import { supabase } from "../../../supabaseClient";

export const fetchUsername = async (userId) => {
  const { data, error } = await supabase.from("profiles").select("username").eq("id", userId).single();

  if (error) {
    throw new Error(error.message);
  }

  return data.username;
};
