const SPECTRUM_LIGHT_COLOR_VALUES = [
  "gray-50",
  "gray-75",
  "gray-100",
  "gray-200",
  "gray-300",
  "gray-400",
  "gray-500",
  "gray-600",
  "gray-700",
  "gray-800",
  "gray-900",
  "red-100",
  "red-200",
  "red-300",
  "red-400",
  "red-500",
];

export { SPECTRUM_LIGHT_COLOR_VALUES };
