import { Well, Text, Flex, View } from "@adobe/react-spectrum";
import ApproveReject from "@spectrum-icons/workflow/ApproveReject";
import CallCenter from "@spectrum-icons/workflow/CallCenter";
import CreditCard from "@spectrum-icons/workflow/CreditCard";
import ThumbUpOutline from "@spectrum-icons/workflow/ThumbUpOutline";
import MapView from "@spectrum-icons/workflow/MapView";

function ReassuranceItemPicto() {
  const items = [
    {
      icon: ApproveReject,
      texts: ["Profils", "vérifiés"],
      ariaLabel: "Vérification des profils",
      width: "size-1600",
    },
    {
      icon: CallCenter,
      texts: ["Une équipe", "à votre écoute"],
      ariaLabel: "Service client",
      width: "size-1600",
    },
    {
      icon: CreditCard,
      texts: ["Aucun", "abonnement"],
      ariaLabel: "Pas de renouvellement automatique",
      width: "size-2400", // Largeur plus large pour cet élément
    },
    {
      icon: ThumbUpOutline,
      texts: ["Profils", "actifs"],
      ariaLabel: "Profils actifs de moins de - 6 mois",
      width: "size-1600",
    },
    {
      icon: MapView,
      texts: ["Carte", "des membres"],
      ariaLabel: "Carte interactive",
      width: "size-1600",
    },
  ];

  return (
    <Well maxWidth="100%">
      <Flex marginY="size-500" direction="row" gap="size-200" wrap justifyContent="center" alignItems="center">
        {items.map(({ icon: Icon, texts, ariaLabel, width }, index) => (
          <View key={index}>
            <Flex width={width} justifyContent="center" direction="column" gap="size-100" alignItems="center">
              <Icon aria-label={ariaLabel} />
              <Text marginTop="size-250">{texts[0]}</Text>
              <Text>{texts[1]}</Text>
            </Flex>
          </View>
        ))}
      </Flex>
    </Well>
  );
}

export default ReassuranceItemPicto;
