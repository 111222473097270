import PropTypes from "prop-types";
import { memo } from "react";
import { Image } from "@adobe/react-spectrum";

const Thumbnail = memo(({ photoAuthor, title, picture, photoURL }) => (
  <Image
    height="size-1600"
    slot="hero"
    alt={`Photo by ${photoAuthor || "LoremPicsum"} on ${photoAuthor ? "Unsplash" : `LoremPicsum, author: ${title}`}`}
    src={picture ? `https://picsum.photos/id/${picture}/800/600` : photoURL}
    objectFit="cover"
    loading="lazy"
  />
));

Thumbnail.propTypes = {
  photoAuthor: PropTypes.string,
  title: PropTypes.string,
  picture: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  photoURL: PropTypes.string,
};

Thumbnail.displayName = "Thumbnail";
export default Thumbnail;
