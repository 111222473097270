import { Button, Flex, Text } from "@adobe/react-spectrum";
import { PUBLIC_ROUTES } from "../../../constants/publicroutes";
import { useNavigate } from "react-router-dom";
import Heart from "@spectrum-icons/workflow/Heart";
import { memo } from "react";
import VisualExemples from "./VisualExamples";

const SecondCTA = memo(function SecondCTA() {
  const navigate = useNavigate();

  return (
    <Flex
      width="100%"
      direction="row"
      gap="size-250"
      alignItems="center"
      justifyContent={{ base: "center", S: "center", M: "start", L: "start" }}
      wrap
    >
      <Flex
        width={{ base: "100%", S: "55%", M: "45%", L: "45%" }}
        gap="size-100"
        alignItems="center"
        justifyContent={{ base: "center", S: "center", M: "start", L: "start" }}
      >
        <VisualExemples />
      </Flex>

      <Flex
        width={{ base: "100%", S: "100%", M: "45%", L: "45%" }}
        direction="column"
        gap="size-100"
        alignItems="center"
        justifyContent="center"
      >
        <span
          style={{
            fontFamily: "monospace",
            color: "DarkGoldenRod",
            fontWeight: "bold",
          }}
          title="attitude alternative"
          lang="en"
        >
          Alternative with attitude
        </span>

        <Button
          UNSAFE_style={{
            transform: "rotate(-2deg)",
            borderRadius: "20% 25% 20% 24%",
          }}
          variant="accent"
          style="fill"
          onPress={() => navigate(PUBLIC_ROUTES.creerCompte)}
        >
          <Heart />
          <Text>Rejoignez la communauté</Text>
        </Button>
      </Flex>
    </Flex>
  );
});

export default SecondCTA;
