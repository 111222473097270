import { memo } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { ActionButton, TooltipTrigger, Tooltip, Text } from "@adobe/react-spectrum";
import { PUBLIC_ROUTES } from "../constants/publicroutes";

import { BOTTOM_NAV } from "../constants/theme";
import GetBottomNavIcon from "../config/GetBottomNavIcon";

const BottomNavItems = memo(({ item, navigate, pathName, sessionRole }) => (
  <TooltipTrigger delay={0} key={uuidv4()}>
    {sessionRole !== "authenticated" && item.label !== BOTTOM_NAV.profile ? (
      <ActionButton
        role="link"
        marginTop="size-50"
        onPress={() => navigate(item.to)}
        isQuiet
        isDisabled
        aria-label={`naviguer vers : ${item.label}`}
        minHeight="size-500"
        minWidth="size-600"
      >
        {GetBottomNavIcon(item.label, false)}
      </ActionButton>
    ) : (
      <ActionButton
        role="button"
        aria-pressed={pathName === item.to}
        marginTop="size-50"
        onPress={() => navigate(sessionRole === "authenticated" ? item.to : PUBLIC_ROUTES.connexion)}
        aria-label={`naviguer vers : ${item.label}, statut : ${
          sessionRole === "authenticated" ? "connecté" : "déconnecté"
        }`}
        minHeight="size-500"
        width="size-800"
        isQuiet
      >
        {GetBottomNavIcon(item.label, pathName === item.to)}
      </ActionButton>
    )}
    <Tooltip>{sessionRole !== "authenticated" ? <Text>Connecte-toi</Text> : item.label}</Tooltip>
  </TooltipTrigger>
));

BottomNavItems.propTypes = {
  item: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  pathName: PropTypes.string.isRequired,
  sessionRole: PropTypes.string,
};

BottomNavItems.displayName = "BottomNavItems";
export default BottomNavItems;
