import { View, Flex, Heading } from "@adobe/react-spectrum";

function Subtitle() {
  return (
    <>
      <View
        width="100%"
        UNSAFE_style={{
          position: "relative",
        }}
      >
        <Flex justifyContent="center">
          <Heading
            marginStart={{ base: "10%", S: "10%", M: "0%", L: "0%" }}
            level={2}
            UNSAFE_style={{
              position: "relative",
              zIndex: 1,
              textDecoration: "underline",
              textDecorationStyle: "wavy",
              fontSize: "2rem",
              fontFamily: "Nunito ExtraBold",
            }}
          >
            Trouve ton Coeur d&apos;artichaut...
          </Heading>
        </Flex>

        <View
          objectFit="cover"
          UNSAFE_style={{
            position: "absolute",
            top: "-5px",
            left: "50%",
            transform: "translateX(-50%) rotate(2deg)",
            height: "90%",
            width: "90%",
            backgroundColor: "#788128",
            borderRadius: "20% 25% 20% 24%",
            zIndex: 0,
          }}
        ></View>
      </View>
    </>
  );
}

Subtitle.displayName = "Subtitle";

export default Subtitle;
