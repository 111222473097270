import { Suspense, lazy } from "react";
import { Flex } from "@adobe/react-spectrum";
import Loading from "../../../components/Loading";

const SignIn = lazy(() => import("../../../components/SignIn"));

export default function Connexion() {
  return (
    <>
      <Flex width="100%" justifyContent="center" alignItems="center">
        <Suspense fallback={<Loading />}>
          <SignIn />
        </Suspense>
      </Flex>
    </>
  );
}
