import { Text, Heading, Link } from "@adobe/react-spectrum";
import { PUBLIC_ROUTES } from "../../../constants/publicroutes";
import { useNavigate } from "react-router-dom";

function Contact() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(PUBLIC_ROUTES.contact);
  };

  // Styles extraits pour éviter de les recalculer à chaque re-rendu
  const headingStyle = {
    textDecoration: "underline",
    textDecorationStyle: "wavy",
    fontSize: "2rem",
  };

  return (
    <>
      <Heading level={2} UNSAFE_style={headingStyle}>
        On papote ?
      </Heading>
      <Heading level={3}>Contactez-nous</Heading>
      <Text>
        <Link variant="secondary" onPress={handleNavigate} aria-label="Laissez-nous votre message">
          Laissez-nous votre message
        </Link>
        , nous nous efforçons de répondre dans les deux jours ouvrés.
      </Text>
    </>
  );
}

export default Contact;
