import { Heading, Flex, Link, ContextualHelp, Content } from "@adobe/react-spectrum";
function ValueProposition({ portfolioUrl, name }) {
  return (
    <>
      <Flex justifyContent="center">
        <Heading
          marginStart={{ base: "10%", S: "10%", M: "0%", L: "0%" }}
          level={1}
          UNSAFE_style={{
            position: "relative",
            fontSize: "2rem",
            fontFamily: "Nunito ExtraBold",
            color: "white",
          }}
        >
          L&apos;app de rencontre <br />
          la plus #Roots{" "}
        </Heading>
        <ContextualHelp variant="info">
          {" "}
          <Content>
            <Link target="blank" rel="noopener noreferrer" variant="secondary" isQuiet href={portfolioUrl}>
              {"Crédit photo : " + name}
            </Link>
          </Content>{" "}
        </ContextualHelp>
      </Flex>
    </>
  );
}

ValueProposition.displayName = "ValueProposition";

export default ValueProposition;
