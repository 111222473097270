import SectionScrollable from "react-scroll-id";

import { CalendarDate } from "@internationalized/date";

import LinkOut from "@spectrum-icons/workflow/LinkOut";
import Circle from "@spectrum-icons/workflow/Circle";
import Mailbox from "@spectrum-icons/workflow/Mailbox";
import Cloud from "@spectrum-icons/workflow/Cloud";
import Events from "@spectrum-icons/workflow/Events";
import Download from "@spectrum-icons/workflow/Download";

import { Flex, Heading, Well, Badge, Text, Divider, View, LabeledValue, Item, ListBox } from "@adobe/react-spectrum";

import LegalBtn from "./LegalBtn";

function LegalContent() {
  return (
    <>
      <LegalBtn />
      <SectionScrollable>
        <Heading id="text-editor" marginTop="size-600" marginBottom="size-10" level={2}>
          <Text>Éditeur</Text>
        </Heading>

        <Divider marginBottom="size-400" />

        <Well>
          <ListBox selectionMode="single" marginY="size-100" aria-label="Liens externes ouvrant une nouvelle fenêtre">
            <Item textValue="Responsable de la publication : Sylvain Ducoulombier">
              <Circle />
              <Text>Responsable de la publication</Text>
              <Text slot="description">Sylvain Ducoulombier</Text>
            </Item>
            <Item textValue="Micro-Entreprise Bluecornflakes">
              <Circle />
              <Text>
                Micro-Entreprise Bluecornflakes • digital native <span title="pieds">👣</span>
              </Text>
              <Text slot="description">Non-diffusible selon L’article A123-96 du code de Commerce</Text>
            </Item>
            <Item
              textValue="Consulter le répertoire SIRENE de l'Institut national de la statistique et des études économiques"
              href="https://www.sirene.fr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOut />

              <Text>
                <LabeledValue label="N° SIRET du RC de Lille, France" value={"520 820 549 000 43"} />
              </Text>
              <Text slot="description">Activité principale exercée : 62.01Z - Programmation informatique</Text>
            </Item>
          </ListBox>
        </Well>

        <Heading id="host-info" marginTop="size-600" marginBottom="size-10" level={2}>
          Hébergement
        </Heading>

        <Divider marginBottom="size-400" />

        <Well marginY="size-200">
          <Heading marginBottom="size-10" level={3}>
            Hébergement courriel
          </Heading>
          <ListBox
            selectionMode="single"
            width="100%"
            marginY="size-100"
            aria-label="Liens externes ouvrant une nouvelle fenêtre"
          >
            <Item textValue="Mailo">
              <Mailbox />
              <Text>Mailo</Text>
              <Text slot="description">
                Mailo est une société établie en France, dans la région Île-de-France. Mailo, la messagerie qui vous
                respecte. Notre engagement : protéger votre vie privée
              </Text>
            </Item>

            <Item textValue="Adresse postale">
              <Mailbox />
              <Text>Adresse postale</Text>
              <Text lang="en" slot="description">
                {" "}
                5 rue paul Ramier 94210 Saint Maur des Fausses, France
              </Text>
            </Item>

            <Item
              aria-label="Envoyer un mail à contact@mailo.com"
              isQuiet
              href="mailto:contact@mailo.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOut />
              <Text>Ecrire un courriel à Mailo : contact@mailo.com</Text>
            </Item>

            <Item
              aria-label="Lien externe pour consulter la page de Mailo"
              textValue="Lien externe pour consulter la page de Mailo"
              href="https://www.mailo.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOut />
              <Text>Lien externe pour consulter la page de Mailo</Text>
            </Item>
          </ListBox>
        </Well>
        <Well marginY="size-200">
          <Heading marginBottom="size-10" level={3} lang="en">
            Hébergement web
          </Heading>
          <ListBox
            selectionMode="single"
            width="100%"
            marginY="size-100"
            aria-label="Liens externes ouvrant une nouvelle fenêtre"
          >
            <Item textValue="Cloudflare">
              <Cloud />
              <Text>Cloudflare</Text>
              <Text slot="description">
                Cloudflare, Inc. est une société constituée dans l&apos;État de Californie aux États-Unis
              </Text>
            </Item>

            <Item textValue="Adresse postale">
              <Cloud />
              <Text>Adresse postale</Text>
              <Text lang="en" slot="description">
                Cloudflare, Inc. 101 Townsend St, San Francisco, CA 94107, États-Unis
              </Text>
            </Item>

            <Item
              aria-label="Contacter Cloudflare par téléphone, numéro USA : +16503198930"
              isQuiet
              href="tel:+16503198930"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOut />
              <Text>Contacter Cloudflare par téléphone, numéro USA : +16503198930</Text>
            </Item>

            <Item
              aria-label="Contacter Cloudflare par courriel : abuse+law@cloudflare.com"
              isQuiet
              href="mailto:abuse+law@cloudflare.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOut />
              <Text>Contacter Cloudflare par courriel : abuse+law@cloudflare.com</Text>
            </Item>

            <Item
              aria-label="Lien externe pour consulter la page de la politique de confidentialité de Cloudflare"
              textValue="Lien externe pour consulter la page de la politique de confidentialité de Cloudflare"
              href="https://www.cloudflare.com/fr-fr/trust-hub/gdpr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOut />
              <Text>
                Lien externe pour consulter la page de la politique de confidentialité de Cloudflare en Français
              </Text>
            </Item>
          </ListBox>
        </Well>
        <Heading id="credit" marginTop="size-600" marginBottom="size-10" level={2}>
          Crédits et licence
        </Heading>

        <Divider marginBottom="size-400" />
        <Well marginY="size-200">
          <Heading level={3}>Equipe</Heading>
          <Text>
            Merci aux membres du Tramway pour leurs aimables retours, aux <span lang="en">Freelances</span> et à tous
            ceux qui témoignent, contribuent ou nous ont aidé ponctuellement pour l&apos;intégration, le logo, la
            conception et les graphismes.
          </Text>
        </Well>
        <Well marginY="size-200">
          <Heading level={3}>Logiciels et services</Heading>
          <ListBox
            selectionMode="single"
            width="100%"
            marginY="size-100"
            aria-label="Liens externes ouvrant une nouvelle fenêtre"
          >
            <Item
              aria-label="Lien externe en anglais pour consulter la page de Vitejs"
              textValue="Lien externe en anglais pour consulter la page de Vitejs"
              href="https://vitejs.dev/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOut />
              <Text>Lien externe en anglais pour consulter la page de Vitejs</Text>
              <Text slot="description">
                Vite est une suite d&apos;outils open source pour développer des interfaces utilisateur.
              </Text>
            </Item>
            <Item
              aria-label="Lien externe en anglais pour consulter la page de React"
              textValue="Lien externe en anglais pour consulter la page de React"
              href="https://react.dev/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOut />
              <Text>Lien externe en anglais pour consulter la page de React</Text>
              <Text slot="description">
                Bibliothèque JavaScript open source pour construire des interfaces utilisateur.
              </Text>
            </Item>{" "}
            <Item
              aria-label="Lien externe en anglais pour consulter la page de Supabase"
              textValue="Lien externe en anglais pour consulter la page de Supabase"
              href="https://supabase.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOut />
              <Text>Lien externe en anglais pour consulter la page de Supabase</Text>
              <Text slot="description">
                Supabase est un service backend qui fournit une base de données Postgres. L&apos;alternative open source
                à Firebase.
              </Text>
            </Item>
            <Item
              aria-label="Lien externe vers le référentiel OPQUAST"
              textValue="Lien externe vers le référentiel OPQUAST"
              href="https://www.opquast.com/rendre-le-web-meilleur/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOut />
              <Text>Lien externe vers le référentiel OPQUAST</Text>
              <Text slot="description">
                Référentiel des bonnes pratiques de qualité Web. Projet collectif et entreprise à mission pour rendre le
                web meilleur.
              </Text>
            </Item>
          </ListBox>
        </Well>
        <Well marginTop="size-200">
          <Heading id="dpo" level={3}>
            Contenus du site Web : tous droits réservés
          </Heading>

          <View marginY="size-200">
            <Text>
              Tout utilisateur du site Internet de l&apos;entreprise Bluecornflakes est considéré comme ayant accepté
              l’application des lois françaises actuellement en vigueur. Il est tenu de respecter les dispositions de la
              loi Informatique et libertés dont la violation est passible de sanctions pénales. Sauf mention contraire,
              l’ensemble des éléments composant ce site sont la propriété exclusive de l&apos;entreprise Bluecornflakes.
            </Text>
          </View>
          <View marginY="size-200">
            {" "}
            <Text>
              Ce site est une oeuvre protégée par la législation sur la propriété intellectuelle. Toute reproduction ou
              représentation faite sans l’accord de l&apos;entreprise Bluecornflakes constitue une contrefaçon.
            </Text>
          </View>
          <View marginY="size-200">
            {" "}
            <Text>
              L’ensemble de ce site relève de la législation française et internationale sur le droit d’auteur et la
              propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les documents
              téléchargeables et les représentations iconographiques et photographiques. La reproduction de tout ou
              partie de ce site sur quelque support que ce soit est formellement interdite sauf autorisation expresse de
              l&apos;entreprise Bluecornflakes. Droits de reproduction et de diffusion réservés Bluecornflakes pour
              Tramway • courant alternatif <span title="marque Française déposée">&#x00AE;</span>.
            </Text>
          </View>
          <View marginY="size-200">
            {" "}
            <Text>
              Malgré tous les soins apportés à la réalisation de ce site et à son actualisation régulière, des erreurs
              peuvent s’être glissées dans les informations et/ou documents présentés. Les utilisateurs du Tramway
              procéderont donc à toutes vérifications utiles.
            </Text>
          </View>
        </Well>
        <Well marginY="size-200">
          <Heading level={3}>Droits de reproduction</Heading>
          <Text>Design et illustrations : tous droits réservés</Text>
        </Well>
        <Well marginY="size-200">
          <Heading level={3}>
            Tramway • courant alternatif <span title="marque Française déposée">&#x00AE;</span>
          </Heading>
          <Text>
            Tramway est une marque Française déposée à l’Institut National de la Propriété Intellectuelle (INPI) sous le
            N° 5027214, toutes les marques citées, noms de produits ou de services, ainsi que les logos sont les
            propriétés de leurs sociétés respectives.
          </Text>
        </Well>
        <Well marginY="size-200">
          <Heading level={3}>Soutien et partenaires</Heading>
          <ListBox
            selectionMode="single"
            width="100%"
            marginY="size-100"
            aria-label="Liens externes ouvrant une nouvelle fenêtre"
          >
            <Item
              aria-label="Lien externe en anglais et en français pour consulter notre Donation Page - Make a difference"
              textValue="Lien externe en anglais et en français pour consulter notre Donation Page - Make a difference"
              href="https://donate.stripe.com/5kA16tezw3OgeKk144"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Events />
              <Text>Donation Page - Make a difference ✨</Text>
              <Text slot="description">
                Lien externe en anglais et en français pour consulter notre page de soutien
              </Text>
            </Item>
          </ListBox>
        </Well>
        <Heading id="dpo" marginTop="size-600" marginBottom="size-10" level={2}>
          Chiffrement des courriels
        </Heading>
        <Divider marginBottom="size-400" />

        <Text>
          Pour garantir la sécurité de nos communications, nous vous proposons d&apos;utiliser le chiffrement PGP pour
          échanger des courriels. Vous pouvez copier notre clé publique PGP et l&apos;ajouter à votre courriel pour
          assurer un échange sécurisé. Assurez-vous d&apos;inclure votre propre clé publique valide avec votre message.{" "}
        </Text>

        <ListBox
          selectionMode="single"
          marginY="size-100"
          aria-label="Chiffrement PGP des échange par courriel, télécharger la clé PGP publique du Tramway"
        >
          <Item download href="/onpapote@tramway_pgp_email.txt" textValue="clé PGP onpapote@tramway.life">
            <Download />

            <Text>clé PGP : onpapote@tramway.life</Text>

            <Text slot="description">
              Télécharger la clé publique.
              <Flex direction="column">
                <LabeledValue
                  labelPosition="side"
                  labelAlign="end"
                  label="Taille"
                  value={1.7}
                  formatOptions={{ style: "unit", unit: "kilobyte" }}
                />
                <LabeledValue label="Nom du fichier" value="onpapote@tramway_pgp_email.txt" />
              </Flex>
            </Text>
          </Item>
        </ListBox>

        <Heading id="dpo" marginTop="size-600" marginBottom="size-10" level={2}>
          Protection des données
        </Heading>
        <Flex wrap justifyContent="start" alignItems="center">
          <Badge variant="positive" margin="size-100">
            Transparence
          </Badge>
          <Badge variant="positive" margin="size-100">
            RGPD
          </Badge>
          <Badge variant="positive" margin="size-100">
            CNIL
          </Badge>
        </Flex>
        <Divider marginBottom="size-400" />

        <ListBox
          target="_self"
          selectionMode="single"
          width="100%"
          marginY="size-100"
          aria-label="Liens externes ouvrant une nouvelle fenêtre"
        >
          <Item aria-label="Déclaration CNIL DPO-145636" textValue="Déclaration CNIL DPO-145636">
            <Circle />
            <Text>Déclaration CNIL : DPO-145636</Text>
            <Text slot="description">
              <LabeledValue label="Prise d'effet" value={new CalendarDate(2024, 4, 24)} />
            </Text>
          </Item>
          <Item
            aria-label="Annexe – Règlement Général sur la Protection des Données (RGPD)"
            textValue="Annexe – Règlement Général sur la Protection des Données (RGPD)"
          >
            <Circle />
            <Text>Consulter librement notre registre des activités de traitement</Text>
            <Text slot="description">
              Le registre des activités de traitement permet de recenser vos traitements de données et de disposer d’une
              vue d’ensemble de ce que le Tramway réalise avec vos données personnelles.
            </Text>
          </Item>
          <Item
            aria-label="Le droit de rectification : corriger vos informations"
            textValue="Contacter le délégué à la protection des données du Tramway"
            isQuiet
            href="mailto:onpapote@tramway.life"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkOut />
            <Text>Le droit de rectification : corriger vos informations</Text>
            <Text slot="description">
              Vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données vous
              concernant. Pour exercer ce droit, il vous suffit d’écrire un courriel à DPO Bluecornflakes à
              l&apos;adresse suivante : onpapote@tramway.life
            </Text>
          </Item>{" "}
          <Item
            aria-label="Lien externe pour consulter la page de la CNIL"
            textValue="Lien externe pour consulter la page de la CNIL"
            href="https://www.cnil.fr/fr/particulier"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkOut />
            <Text>Lien externe pour consulter la page de la CNIL</Text>
            <Text slot="description">CNIL : Commission Nationale de l’Informatique et des Libertés</Text>
          </Item>
        </ListBox>
        <Heading id="warning-message" marginTop="size-600" marginBottom="size-10" level={2}>
          Avertissement
        </Heading>

        <Divider marginBottom="size-400" />

        <View marginY="size-200">
          <Text>
            Il appartient à l’utilisateur de ce site de prendre toutes les mesures appropriées de façon à protéger ses
            propres données et/ou logiciels de la contamination par d’éventuels virus circulant sur le réseau Internet.
            Le Tramway décline toute responsabilité à un éventuel dommage survenu pendant la consultation ou après un
            téléchargement effectué à partir du présent site.
          </Text>
        </View>
        <View marginY="size-200">
          <Text>
            L’utilisateur reconnaît avoir été informé que le présent site est accessible 24 heures sur 24 et 7 jours sur
            7, à l’exception des cas de force majeure, difficultés informatiques ou difficultés techniques.
          </Text>
        </View>
        <View marginY="size-200">
          <Text>
            Nous ne pouvons assurer la confidentialité des messages que vous pouvez nous adresser transitant par un
            réseau ouvert de télécommunications.
          </Text>
        </View>
        <View marginY="size-200">
          <Text>
            Les liens vers d’autres sites ne sont communiqués qu’à titre indicatif et ne sauraient en aucun cas engager
            la responsabilité du Tramway, tant en ce qui concerne leur contenu que les conditions d’accès.
          </Text>
        </View>

        <Text></Text>
        <Heading id="cookies" marginTop="size-600" marginBottom="size-10" level={2}>
          Cookies et autres traceurs
        </Heading>

        <Divider marginBottom="size-400" />

        <View marginY="size-200">
          <Text>
            Les cookies sont des éléments d’information qu’un site Internet transmet au disque dur d’un ordinateur
            individuel afin de garder un enregistrement du passage ; les mots de passe ne sont pas gardés dans les
            cookies. Les cookies permettent aux utilisateurs de conserver la mémoire de leurs paramètres personnels pour
            accéder à ce site et ainsi faciliter leur navigation, mais aussi d’obtenir des statistiques.
          </Text>
        </View>
        <View marginY="size-200">
          <Text>
            Les cookies du Tramway sont strictement nécessaires à la fourniture d&apos;un service de communication en
            ligne expressément demandé par l&apos;utilisateur ou ont pour finalité exclusive de permettre ou faciliter
            une communication par voie électronique.
          </Text>
        </View>
      </SectionScrollable>
    </>
  );
}

export default LegalContent;
