import { View } from "@adobe/react-spectrum";
import PropTypes from "prop-types";
import FeaturedIn from "./FeaturedIn";
import SubFeatures from "./SubFeatures";
import FeatureOne from "./FeatureOne";
import ReassuranceItemPicto from "./ReassuranceItemPicto";
import { memo } from "react";

const FeaturesBenefits = memo(function FeaturesBenefits({ isSmallScreen }) {
  return (
    <View paddingStart="size-500" paddingEnd="size-500" paddingTop="size-500" paddingBottom="size-500">
      <FeaturedIn />
      <FeatureOne isSmallScreen={isSmallScreen} />
      <ReassuranceItemPicto />
      <SubFeatures isSmallScreen={isSmallScreen} />
    </View>
  );
});

FeaturesBenefits.propTypes = {
  isSmallScreen: PropTypes.bool.isRequired,
};

export default FeaturesBenefits;
