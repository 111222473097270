import { lazy, Suspense, memo } from "react";

import useVibration from "../hooks/useVibration";

import clipboard from "clipboardy";

import { ToastQueue } from "@react-spectrum/toast";
import Copy from "@spectrum-icons/workflow/Copy";

import { ActionButton, DialogTrigger, Flex, Text } from "@adobe/react-spectrum";

import Loading from "./Loading";
const SetlazyDPODialog = memo(lazy(() => import("./SetDPODialog")));

function LegalBtn() {
  const { vibrate } = useVibration();

  const getSIRETCopy = () => {
    vibrate();
    clipboard.write("Bluecornflakes - SIRET 520 820 549 000 43");
    ToastQueue.positive("Bluecornflakes - SIRET 520 820 549 000 43", { timeout: 5000 });
  };

  const getDPO_CNILCopy = () => {
    vibrate();
    clipboard.write("Tramway : Déclaration CNIL DPO-145636");
    ToastQueue.positive("Tramway : Déclaration CNIL DPO-145636", { timeout: 5000 });
  };

  return (
    <Flex
      wrap
      marginY={{ base: "size-200", L: "size-0" }}
      direction="row"
      alignItems="center"
      justifyContent="start"
      gap="size-200"
    >
      <ActionButton marginY="size-50" onPress={getSIRETCopy} aria-label="Copier le numéro de SIRET l'entreprise">
        <Copy /> <Text>NUMERO SIRET</Text>
      </ActionButton>
      <ActionButton marginY="size-50" onPress={getDPO_CNILCopy} aria-label="Copier le numéro de déclaration CNIL">
        <Copy /> <Text>DECLARATION CNIL</Text>
      </ActionButton>

      <DialogTrigger isDismissable type="tray">
        <ActionButton marginY="size-50">Le registre des activités de traitement</ActionButton>
        {() => (
          <Suspense fallback={<Loading />}>
            <SetlazyDPODialog />
          </Suspense>
        )}
      </DialogTrigger>
    </Flex>
  );
}

export default LegalBtn;
