import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { getLocalTimeZone, today, CalendarDate, DateFormatter } from "@internationalized/date";
import { DatePicker, ContextualHelp, Heading, Content, Flex, Text, LabeledValue } from "@adobe/react-spectrum";
import useAgeCalculation from "./Hooks/useAgeCalculation";

const AgePicker = () => {
  const now = today(getLocalTimeZone());
  const majBirth = now.subtract({ years: 18 });
  const maxAge = now.subtract({ years: 150 });

  const [date, setDate] = useState(new CalendarDate(1967, 6, 21));

  const validateDate = useCallback(
    (date) => {
      if (date) {
        if (majBirth.compare(date) <= 0) {
          return "La majorité est requise.";
        }
        if (maxAge.compare(date) >= 0) {
          return "L'age maximum est de 150 ans, partage nous le secret de ta longevité ;-) 🗝️ 🎂 ✨";
        }
      }
      return null;
    },
    [majBirth, maxAge],
  );

  const age = useAgeCalculation(date);
  console.log(date.toString());

  return (
    <>
      <DatePicker
        // placeholderValue={new CalendarDate(1967, 6, 21)}
        isQuiet
        isRequired
        width="100%"
        granularity="day"
        maxVisibleMonths={1}
        necessityIndicator="icon"
        validationBehavior="native"
        value={date}
        onChange={setDate}
        label="Ma date de naissance"
        name="anniversaire"
        validate={validateDate}
        isDateUnavailable={(date) => majBirth.compare(date) <= 0}
        contextualHelp={
          <ContextualHelp variant="info">
            <Heading>Votre date de naissance</Heading>
            <Content>
              <Heading>Majorité Requise</Heading>
              <Text>
                Pour vous inscrire au service Tramway, vous devez être âgé d&apos;au moins 18 ans et être reconnu majeur
                selon la législation en vigueur.
              </Text>
              <Heading>Date par défaut</Heading>
              <Text>
                Exemple : ici la date est réglée sur <span lang="en">Summer of Love</span> lors de l&apos;été 1967,
                faisant ainsi découvrir la contre-culture hippie au grand public.
              </Text>
            </Content>
          </ContextualHelp>
        }
      />
      <Flex direction="horizontal" marginY="size-100" gap="size-100">
        <LabeledValue labelPosition="side" labelAlign="end" label="Age" value={age ? age + " ans" : ""} />
      </Flex>
    </>
  );
};

AgePicker.propTypes = {
  setZodiac: PropTypes.func,
};

AgePicker.displayName = "AgePicker";
export default AgePicker;
