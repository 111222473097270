import { useState } from "react";
import { Flex, Heading, Text, Divider, Item, ListBox, Section } from "@adobe/react-spectrum";
import SentimentPositive from "@spectrum-icons/workflow/SentimentPositive";
import QuickSelect from "@spectrum-icons/workflow/QuickSelect";
import User from "@spectrum-icons/workflow/User";
import Heart from "@spectrum-icons/workflow/Heart";
import Slow from "@spectrum-icons/workflow/Slow";
import Money from "@spectrum-icons/workflow/Money";
import Visibility from "@spectrum-icons/workflow/Visibility";
import Demographic from "@spectrum-icons/workflow/Demographic";
import Reuse from "@spectrum-icons/workflow/Reuse";
import SentimentNeutral from "@spectrum-icons/workflow/SentimentNeutral";

const ethicsItems = Object.freeze([
  {
    name: "Outre le strict respect des réglementations en vigueur, Tramway s'engage également à :",
    children: [
      {
        id: 1,
        icon: <SentimentNeutral />,
        name: "Pas de Robots",
        description: "Nous n'utilisons pas de robots de dialogue ni ne créons de faux profils.",
      },
      {
        id: 2,
        icon: <Reuse />,
        name: "Pas de Renouvellement automatique d'abonnement",
        description: "Nous n'avons pas de renouvellement automatique d'abonnement par prélèvement bancaire.",
      },
      {
        id: 3,
        icon: <Visibility />,
        name: "Respect de la Vie privée",
        description:
          "Nous respectons la vie privée de nos membres et ne publions pas leurs données sans leur consentement.",
      },
      {
        id: 4,
        icon: <Demographic />,
        name: "Des membres actifs",
        description:
          "Nous encourageons l'activité de nos membres et supprimons les profils inactifs depuis plus d'un an.",
      },
      {
        id: 5,
        icon: <Money />,
        name: "Pas de Contact surtaxé",
        description: "Nous ne proposons pas de numéro de téléphone surtaxé pour contacter notre service.",
      },
    ],
  },
]);

const simplicityItems = Object.freeze([
  {
    name: "Simplement...",
    children: [
      {
        id: 6,
        icon: <SentimentPositive />,
        name: "Soyez un être humain",
        description: "Ecrivez des messages spécifiquement destinés à leur destinataire.",
      },
      {
        id: 7,
        icon: <QuickSelect />,
        name: "Respectez les droits d'auteur",
        description:
          "Restez dans votre droit si vous souhaitez partager les textes et les images d'autres personnes - vérifiez leur statut juridique !",
      },
      {
        id: 8,
        icon: <User />,
        name: "Créez votre propre profil",
        description:
          "Une personne, un profil : utilisez votre pseudonyme ou votre vrai nom, mais n'usurpez jamais l'identité de quelqu'un d'autre, qu'il soit réel ou imaginaire.",
      },
      {
        id: 9,
        icon: <Heart />,
        name: "Soyez vous-même, serviable, gentil, responsable",
        description: "Soyez respectueux des autres et abstenez-vous de tout comportement abusif.",
      },
      {
        id: 10,
        icon: <Slow />,
        name: "Notre devise est la simplicité",
        description: "Ne demandez ni n'acceptez aucune forme d'échange monétaire.",
      },
    ],
  },
]);

export default function Manifesto() {
  const [selected, setSelected] = useState(new Set());

  return (
    <Flex direction="column" maxWidth="100%">
      <Text>
        Tramway est une petite plateforme indépendante de rencontre qui s&apos;inspire des bonnes pratiques de qualité
        Web définies par le référentiel OPQUAST.
      </Text>

      <Heading marginTop="size-600" marginBottom="size-10" level={2}>
        Un petit écosystème de rencontre <span title="emoji : mandala yoga = inclusion. Non exclusion.">❂</span>
      </Heading>

      <Divider marginBottom="size-400" />

      <Text>
        Tramway est une plateforme Francophone 100% accessible, un petit écosystème de rencontre amoureuses. Un
        ecosysteme de membres, de vagabonds des étoiles, de ceux qui sont des naufragés du tout conventionnel,
        d&apos;artistes, de créateurs qui participent à la création d&apos;œuvres d&apos;art, d&apos;événements et
        d&apos;éco-lieux dans le monde entier. Nous voulons un monde qui encourage la confiance, l&apos;aventure et les
        relations interculturelles.
      </Text>

      <Heading marginTop="size-600" marginBottom="size-10" level={2}>
        Respect des réglementations et intégrité
      </Heading>

      <Divider marginBottom="size-400" />

      <ListBox
        width="100%"
        aria-label="Ethique"
        items={ethicsItems}
        selectedKeys={selected}
        selectionMode="multiple"
        onSelectionChange={setSelected}
      >
        {(section) => (
          <Section key={section.name} items={section.children} title={section.name}>
            {(item) => (
              <Item key={item.id} textValue={item.name}>
                {item.icon}
                <Text>{item.name}</Text>
                <Text slot="description">{item.description}</Text>
              </Item>
            )}
          </Section>
        )}
      </ListBox>

      <Heading marginTop="size-600" marginBottom="size-10" level={2}>
        Du Bon sens <span title="emoji : Paix et Amour">✌️</span> <span lang="en">Peace & Love</span>
      </Heading>

      <Divider marginBottom="size-400" />

      <Text>
        Pour que cela se produise, Tramway a établi quelques règles de base qui s&apos;appliquent à tous. Merci de
        respecter ces règles : Soyez amical et sachez quand arrêter d&apos;envoyer un message à quelqu&apos;un. Nous ne
        tolérons pas les membres abusifs. Nous ne tolérons pas les spams, les publicités et autres contenus
        répréhensibles.
      </Text>

      <ListBox
        width="100%"
        aria-label="Raccourcis clavier"
        items={simplicityItems}
        selectedKeys={selected}
        selectionMode="multiple"
        onSelectionChange={setSelected}
      >
        {(section) => (
          <Section key={section.name} items={section.children} title={section.name}>
            {(item) => (
              <Item key={item.id} textValue={item.name}>
                {item.icon}
                <Text>{item.name}</Text>
                <Text slot="description">{item.description}</Text>
              </Item>
            )}
          </Section>
        )}
      </ListBox>

      <Heading marginTop="size-600" marginBottom="size-10" level={2}>
        En savoir plus...
      </Heading>

      <Divider marginBottom="size-400" />

      <Text>
        Si vous ne respectez pas ces règles, nous pourrons cesser de vous offrir nos services sans préavis. Nous
        essayons de garder nos règles simples et nous valorisons la transparence. Nous mettrons à jour ces règles si
        nécessaire. Voir aussi notre page sur la protection de la vie privée. Nous vous remercions de votre attention.
        Un membres du Tramway est une personne physique disposant de la pleine capacité juridique, majeur et ayant
        débuté son parcours d’inscription depuis un smartphone, une tablette, tout client capable d’installer une
        Application mobile et de naviguer sur internet, particulièrement un ordinateur depuis le navigateur Chrome qui
        propose automatiquement l&apos;instalation de l&apos;application progressive Tramway. MERCI
      </Text>
    </Flex>
  );
}
