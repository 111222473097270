import { Flex, View } from "@adobe/react-spectrum";

const LoadingInCircle = () => {
  return (
    <Flex width="100%" height="size-1600" justifyContent="center" alignItems="center">
      <View UNSAFE_className="heart" />
    </Flex>
  );
};

LoadingInCircle.displayName = "LoadingInCircle";
export default LoadingInCircle;
