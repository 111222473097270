import { ScrollTo } from "react-scroll-id";
import { Item, ListBox, Text } from "@adobe/react-spectrum";
import BreadcrumbNavigation from "@spectrum-icons/workflow/BreadcrumbNavigation";

function LegalSummary() {
  let hash = [
    { id: "text-editor", name: "Éditeur" },
    { id: "host-info", name: "Hébergement" },
    { id: "credit", name: "Crédits et licence" },
    { id: "dpo", name: "Protection des données" },
    { id: "warning-message", name: "Avertissement" },
    { id: "cookies", name: "Cookies et autres traceurs" },
  ];

  let handleHashNav = (hashNav) => {
    const target = hashNav.currentKey;
    ScrollTo({ targetId: target, behavior: "smooth" });
  };

  return (
    <>
      <ListBox
        maxWidth="100%"
        aria-label="Liens vers les sections de la pages"
        items={hash}
        selectionMode="single"
        onSelectionChange={(hashNav) => handleHashNav(hashNav)}
      >
        {(item) => (
          <Item key={item?.id} textValue={item?.name}>
            <BreadcrumbNavigation />
            <Text>{item?.name}</Text>
          </Item>
        )}
      </ListBox>
    </>
  );
}

export default LegalSummary;
