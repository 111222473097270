import PropTypes from "prop-types";
import { useMemo, lazy, memo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { PUBLIC_ROUTES } from "../constants/publicroutes";
import MFAHOC from "./MFAHOC";

const SetlazyMFAForm = memo(lazy(() => import("./FormMFA")));
const MFAHOCWrapper = MFAHOC(SetlazyMFAForm);

export default function ProtectedRoute({ isAuthenticated, currentLevelMFA, nextLevelMFA }) {
  const isMFACheckRequired = useMemo(
    () => nextLevelMFA === "aal2" && nextLevelMFA !== currentLevelMFA,
    [nextLevelMFA, currentLevelMFA],
  );

  if (isAuthenticated !== "authenticated") {
    return <Navigate to={`../${PUBLIC_ROUTES.connexion}`} replace />;
  }

  if (isMFACheckRequired) {
    return <MFAHOCWrapper />;
  }

  return <Outlet />;
}

ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.string.isRequired,
  currentLevelMFA: PropTypes.string,
  nextLevelMFA: PropTypes.string,
};
