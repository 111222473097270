import { Accordion } from "@react-spectrum/accordion";
import { Section, Heading } from "@adobe/react-spectrum";

export default function Faq() {
  return (
    <>
      <Heading
        UNSAFE_style={{
          textDecoration: "underline",
          textDecorationStyle: "wavy",
          fontSize: "2rem",
        }}
        level={2}
      >
        FAQ
      </Heading>
      <Heading level={3}>Foire aux questions</Heading>

      <Accordion aria-label="Questions fréquentes" maxWidth="100%">
        <Section
          aria-label="Questions fréquentes"
          key="2"
          title="Pourquoi choisir Tramway pour des rencontres authentiques ?
"
        >
          Tramway est fait pour les &quot;Roots&quot;. Si vous cherchez une connexion authentique, vous êtes au bon
          endroit. Ce n’est pas un simple site de rencontre pour adultes de tous horizons, mais un tiers-lien où la
          nature, la durabilité et l’authenticité sont célébrées. Ici, on crée des souvenirs qui durent, dans un esprit
          de simplicité et de respect. Une petite plateforme indépendante de rencontres, qui offre une connexion
          authentique, durable et respectueuse.
        </Section>

        <Section aria-label="Questions fréquentes" key="3" title="Est-ce qu'il existe une application mobile Tramway ?">
          Oui, Tramway est une application web progressive (PWA) que vous pouvez facilement installer sur votre
          smartphone via le navigateur Google Chrome. Elle est également compatible avec les tablettes et les
          ordinateurs. Lors de votre première visite via Chrome, le navigateur vous proposera automatiquement
          l&apos;installation pour un accès rapide et pratique.
        </Section>
        <Section
          aria-label="Questions fréquentes"
          key="4"
          title="Une app de rencontre inclusive pour les personnes en situation de handicap"
        >
          Tramway s&apos;engage dans l&apos;accessibilité numérique. Cela garantit la participation pleine et entière
          des personnes en situation de handicap à la société.
        </Section>
        <Section aria-label="Questions fréquentes" key="5" title="Puis-je tester Tramway gratuitement ?">
          Oui, la visite gratuite vous permet de découvrir le site et d&apos;évaluer s&apos;il répond à vos attentes.
          Vous pouvez créer et compléter votre profil, faire des recherches parmi les profils disponibles, et plus
          encore.
        </Section>
        <Section
          aria-label="Questions fréquentes"
          key="6"
          title="Notre mission — Qui sommes-nous ?
"
        >
          Bluecornflakes est une petite entreprise indépendante, créatrice de Tramway, un site de rencontre de référence
          depuis 2024, dédié à ceux qui recherchent des connexions authentiques dans le secteur alternatif,
          l&apos;outdoor et la consommation responsable. Nous sommes fermement engagés à assurer une modération
          rigoureuse pour combattre les escroqueries en ligne telles que les arnaques aux sentiments et les fraudeurs,
          afin de garantir à 100% la véracité des profils. Pour toute question, n&apos;hésitez pas à nous contacter en
          cliquant sur le lien &quot;Contact&quot;, disponible en bas de page. Laissez-nous votre message, et nous nous
          efforcerons de répondre sous deux jours ouvrés.
        </Section>
      </Accordion>
    </>
  );
}
