import { Heading } from "@adobe/react-spectrum";
import TwitterTimeline from "../../../components/TwitterTimeline";
import { memo } from "react";

// Styles extraits pour éviter la redondance
const headingStyle = {
  textDecoration: "underline",
  textDecorationStyle: "wavy",
  fontSize: "2rem",
};

const Actuality = memo(function Actuality() {
  return (
    <>
      <Heading level={2} UNSAFE_style={headingStyle}>
        Nous suivre
      </Heading>
      <Heading level={3}>Nous célébrons les alternatives et les jus de légumes</Heading>
      <TwitterTimeline />
    </>
  );
});

export default Actuality;
