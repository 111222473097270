import { lazy, Suspense, memo, useContext } from "react";
import UserSession from "../../contexts/UserSession";
import Loading from "../Loading";

const LazyUserProfilDetails = memo(lazy(() => import("./ProfilDetails")));
const LazyProfilPictures = memo(lazy(() => import("../MemberMedia/ProfilPictures")));
const SetlazySettings = memo(lazy(() => import("../../components/Settings")));

import AgePicker from "./AgePicker";

import {
  Tabs,
  TabPanels,
  TabList,
  Text,
  ContextualHelp,
  Content,
  Heading,
  ActionButton,
  ListView,
  Item,
} from "@adobe/react-spectrum";

import Photo from "@spectrum-icons/workflow/ImageAdd";
import Details from "@spectrum-icons/workflow/UserEdit";
import Settings from "@spectrum-icons/workflow/Settings";
import ApproveReject from "@spectrum-icons/workflow/ApproveReject";


export default function HomeTabs() {
  const { session, sessionRole, sessionEmail } = useContext(UserSession);
  const userId = session?.user?.id;
  const isAuth = sessionRole === "authenticated" ? true : false;

  return (
    <>
      <Tabs
        alignSelf="center"
        justifySelf="center"
        disallowEmptySelection="true"
        defaultSelectedKey="1"
        density="regular"
        aria-label="Les membres du Tramway sont tous ici"
        // disabledKeys={[ "2", "3"]}
      >
        <TabList>
          <Item key="1" textValue="ID">
            <ApproveReject size="S" aria-label="ID" />
            <Text>Essentiel</Text>
          </Item>
          <Item key="2" textValue="Gallerie">
            <Photo size="S" aria-label="Gallerie" />
            <Text>Gallerie</Text>
          </Item>

          <Item key="3" textValue="Profil">
            <Details size="S" aria-label="Profil" />
            <Text>Profil</Text>
          </Item>
          <Item key="4" textValue="Parametres">
            <Settings size="S" aria-label="Parametres" />
            <Text>Parametres</Text>
          </Item>
        </TabList>

        <TabPanels marginTop="size-200" marginX="size-0">
          <Item key="1" textValue="Apporte une Bio attrayante à ton profil.">
           Complète pour activer ton profil
            <ListView
              isQuiet
              density="spacious"
              overflowMode="truncate"
              selectionMode="single"
              selectionStyle="highlight"
              maxWidth="100%"
              aria-label="ListView example with complex items"
              disabledKeys={["2", "3"]}
              disabledBehavior="all"
            >
              <Item key="1" textValue="Information personnelles">
                <Text>Pseudo</Text>
                <Text slot="description">Pseudo</Text>
                <ActionButton>
                  <Text>Editer</Text>
                </ActionButton>
              </Item>
              <Item key="2" textValue="Date de naissance">
                <Text>Anniversaire</Text>
                <Text slot="description">Date de naissance</Text>
                <ActionButton>
                  <Text>Editer</Text>
                </ActionButton>
              </Item>
              <Item key="3" textValue="Mon identité sexuelle">
                <Text>Genre et orientation</Text>
                <Text slot="description">Mon identité sexuelle</Text>
                <ActionButton>
                  <Text>Editer</Text>
                </ActionButton>
              </Item>

              <Item key="4" textValue="Lieu d'habitation">
                <Text>Adresse</Text>
                <Text slot="description">Lieu d'habitation</Text>
                <ActionButton>
                  <Text>Editer</Text>
                </ActionButton>
              </Item>
            </ListView>

            <AgePicker />
          </Item>

          <Item key="2" textValue="Modifier ma gallerie">
            Modifier ma gallerie
            <ContextualHelp variant="info">
              <Heading>Gallerie</Heading>
              <Content>
                <Text>Ajouter des photos depuis mon appareil au format JPG, PNG ou WEBP.</Text>
              </Content>
            </ContextualHelp>
            <LazyProfilPictures userId={userId} />
          </Item>

          <Item key="3" textValue="Ce qui te rends unique.">
            Ce qui te rends unique. <span title="emoji : couronne, étincelles">👑✨</span>
            <Suspense fallback={<Loading />}>
              <LazyUserProfilDetails userId={userId} />
            </Suspense>
          </Item>


          <Item key="4" textValue="Parametres du compte">
            Parametres du compte
            <Suspense fallback={<Loading />}>
            <SetlazySettings userId={userId} isAuth={isAuth} sessionEmail={sessionEmail} />
            </Suspense>
          </Item>
        </TabPanels>
      </Tabs>
    </>
  );
}
