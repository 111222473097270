import { Heading, Content, IllustratedMessage } from "@adobe/react-spectrum";
import PrivateLayout from "../../components/Layout/PrivateLayout";
import Chat from "@spectrum-icons/workflow/Chat";
import UploadAudio from "../../components/MemberData/UploadAudio";
export default function Messages() {
  return (
    <PrivateLayout>
      <IllustratedMessage maxWidth="100%" margin="size-400">
        <Chat size="L" aria-label="Messagerie du Tramway" />
        <Heading>Il n&apos;y a pas encore de courrier.</Heading>
        <Content>
          Tramway • courant alternatif <span title="Emoji : Marque déposée"></span>®
          <UploadAudio
                conversationId={"c91658ff-77e9-4be1-9234-bfe5ba59a6c8"}
                senderId={"6037a34a-fdf1-49bc-a512-db2129aaa2fb"}
                receiverId={"4831e79f-ef89-4d63-8551-3b539e89c6b2"}
              />


        </Content>

      </IllustratedMessage>
    </PrivateLayout>
  );
}
