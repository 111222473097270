import { useState, lazy, Suspense, memo } from "react";

import Loading from "../../components/Loading";
import HomeTabs from "../../components/HomeTabs";
import PrivateLayout from "../../components/Layout/PrivateLayout";
import FullScreenMapBtn from "../../components/GeoCode/FullScreenMapBtn";

const SetlazySearchMembers = memo(lazy(() => import("../../components/MemberData/SearchMembersDialog")));
const SetlazyUserBlockedList = memo(lazy(() => import("../../components/UsersExcludeDialog")));

import UsersExclude from "@spectrum-icons/workflow/UsersExclude";
import Properties from "@spectrum-icons/workflow/Properties";
import Chat from "@spectrum-icons/workflow/Chat";
import SortOrderDown from "@spectrum-icons/workflow/SortOrderDown";

import { ActionButton, Flex, Text, DialogTrigger, ToggleButton, Tooltip, TooltipTrigger } from "@adobe/react-spectrum";

export default function Accueil() {
  let [isSelected, setSelected] = useState(true);

  return (
    <>
      <PrivateLayout
        width={{ base: "100%", L: "70%", M: "100%", S: "100%" }}
        borderWidth={{ base: "thin", L: "thin", M: "thin", S: "thin" }}
        borderColor="dark"
        borderRadius="medium"
        padding={{ base: "size-100", L: "size-250", M: "size-250", S: "size-250" }}
        height="size-5000"
      >
        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap="size-100">
            {/* RECHERCHE INPUT */}
            <ToggleButton isSelected={isSelected} onChange={setSelected} aria-label="Les membres en ligne uniquement">
              {isSelected ? (
                <>
                  <Chat />
                  <Text>En ligne</Text>
                </>
              ) : (
                <>
                  <SortOrderDown />
                  <Text>Nouveaux</Text>
                </>
              )}
            </ToggleButton>

            {/* MAP */}
            <FullScreenMapBtn />

            {/* RECHERCHE */}
            <DialogTrigger type="tray">
              <TooltipTrigger>
                <ActionButton
                  aria-label="Pramètres de recherche que nous dans ton compte personnel pour des recherches ultérieures."
                  marginY="size-200"
                >
                  <Properties />
                </ActionButton>
                <Tooltip>Préférences de recherche</Tooltip>
              </TooltipTrigger>
              {() => (
                <Suspense fallback={<Loading />}>
                  <SetlazySearchMembers />
                </Suspense>
              )}
            </DialogTrigger>
          </Flex>

          {/* QUEBLOLISTE */}
          <DialogTrigger type="popover">
            <TooltipTrigger>
              <ActionButton aria-label="Liste des utilisateurs que tu as bloqué" isQuiet marginY="size-200">
                <UsersExclude />
              </ActionButton>
              <Tooltip>Les membres bloqués</Tooltip>
            </TooltipTrigger>
            {() => (
              <Suspense fallback={<Loading />}>
                <SetlazyUserBlockedList />
              </Suspense>
            )}
          </DialogTrigger>
        </Flex>

        <HomeTabs />
      </PrivateLayout>
    </>
  );
}
