import { today, getLocalTimeZone } from "@internationalized/date";

const useAgeCalculation = (date) => {
  const now = today(getLocalTimeZone());
  if (!date) return "";
  const age =
    now.month < date.month || (now.month === date.month && now.day < date.day)
      ? now.year - date.year - 1
      : now.year - date.year;
  return age;
};

export default useAgeCalculation;
