import { useNavigate } from "react-router-dom";

import { Header, Flex, Text, Button } from "@adobe/react-spectrum";
import SignIn from "@spectrum-icons/workflow/Login";
import Contact from "@spectrum-icons/workflow/Subscribe";
import TramFlashIcon from "../../TramFlashIcon";
import { PUBLIC_ROUTES } from "../../../constants/publicroutes";

function HeaderLandingPage() {
  const navigate = useNavigate();

  return (
    <Header marginY="size-250" marginX={{ base: "size-250", S: "size-500" }}>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" justifyContent="center">
          <TramFlashIcon aria-label="Un chemin intérieur, une quête personnelle, un mandala, un flow, un paysage stratifié de karma et de parcours" />
          <Text UNSAFE_className="landing-page-title" margin="size-10" level={1}>
            Tramway
          </Text>
        </Flex>

        <Flex gap="size-250">
          <Button
            aria-label="connexion"
            width="size-600"
            variant="accent"
            style="fill"
            onPress={() => navigate("../" + PUBLIC_ROUTES.connexion)}
          >
            <SignIn />
          </Button>
          <Button
            aria-label="contacter Tramway"
            width="size-600"
            variant="primary"
            onPress={() => navigate("../" + PUBLIC_ROUTES.contact)}
          >
            <Contact />
          </Button>
        </Flex>
      </Flex>
    </Header>
  );
}

export default HeaderLandingPage;
