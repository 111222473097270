import { useMemo, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import FooterItems from "./BottomNavItems";
import UserSession from "../contexts/UserSession";
import { BOTTOM_NAV_ITEMS } from "../constants/footer_items";

import { Grid, View } from "@adobe/react-spectrum";

const BottomNav = () => {
  const memoizedBottomNavItems = useMemo(() => BOTTOM_NAV_ITEMS, []);
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const { sessionRole } = useContext(UserSession);

  return (
    <View backgroundColor="gray-50" height="size-600" bottom="size-0" zIndex="1000" position="fixed" width="100%">
      <Grid width="100%" areas={["1 2 3 4"]} justifyContent="space-evenly">
        {memoizedBottomNavItems.map((item) => (
          <FooterItems key={uuidv4()} item={item} navigate={navigate} pathName={pathName} sessionRole={sessionRole} />
        ))}
      </Grid>
    </View>
  );
};

export default BottomNav;
