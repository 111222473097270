import { memo, useContext } from "react";
import CircleCard from "../Circles/CircleCard";
import PrivateLayout from "../../components/Layout/PrivateLayout";
import { useCircles } from "../Circles/circlesHooks/useCircles";
import { Flex, View } from "@adobe/react-spectrum";
import Loading from "../Loading";
import UserSession from "../../contexts/UserSession";

const AllCircleCards = memo(() => {
  const { session } = useContext(UserSession);
  const userId = session?.user?.id;
  const { circles, isLoading, isError, subscribedCircles } = useCircles(userId);

  if (isLoading) return <Loading />;
  if (isError) return <View>Erreur de chargement des cercles. Veuillez réessayer plus tard.</View>;

  const subscribedCircleIds = new Set(subscribedCircles?.map((subCircle) => subCircle?.circle_id));

  const isSelected = (circleId) => subscribedCircleIds.has(circleId);

  return (
    <PrivateLayout>
      <Flex marginY="size-100" justifyContent="space-evenly" direction="row" gap="size-300" wrap>
        {circles?.map((circle) => (
          <CircleCard key={circle?.circle_id} circle={circle} isSelected={isSelected(circle?.circle_id)} />
        ))}
      </Flex>
    </PrivateLayout>
  );
});

AllCircleCards.displayName = "AllCircleCards";
export default AllCircleCards;
