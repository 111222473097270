import PropTypes from "prop-types";
import { NAV } from "../constants/nav";

import Login from "@spectrum-icons/workflow/Login";
import Logout from "@spectrum-icons/workflow/LogOut";
import Contact from "@spectrum-icons/workflow/EditCircle";
import Asterisk from "@spectrum-icons/workflow/Asterisk";
import Info from "@spectrum-icons/workflow/Info";
import Help from "@spectrum-icons/workflow/Help";
import Engagement from "@spectrum-icons/workflow/Engagement";
import News from "@spectrum-icons/workflow/News";
import Book from "@spectrum-icons/workflow/Bookmark";
import Invite from "@spectrum-icons/workflow/Invite";
export default function GetMenuIcon(key, label) {
  switch (key) {
    case NAV.connexion:
      return <Login aria-label={label} color="positive" />;
    case NAV.deconnexion:
      return <Logout aria-label={label} color="positive" />;
    case NAV.creerCompte:
      return <Invite aria-label={label} />;
    case NAV.compte:
      return;
    case NAV.manifesto:
      return <Engagement aria-label={label} />;
    case NAV.avis:
      return <Book aria-label={label} />;
    case NAV.feed:
      return <News aria-label={label} />;
    case NAV.contact:
      return <Contact aria-label={label} />;
    case NAV.aide:
      return <Help aria-label={label} />;
    case NAV.mentionsLegales:
      return <Info aria-label={label} />;
    case NAV.accessibilite:
      return <Asterisk aria-label={label} color="informative" />;
    default:
      return "";
  }
}

GetMenuIcon.propTypes = {
  key: PropTypes.string.isRequired,
};
