import { useContext } from "react";
import TextStyle from "@spectrum-icons/workflow/TextStyle";

import { Button } from "@adobe/react-spectrum";
import UserAccessibility from "../contexts/UserAccessibility";

export default function UserShareTheAppByMailButton() {
  const { openDyslexic, toggleTypo } = useContext(UserAccessibility);
  return (
    <>
      <Button
        aria-label={`Police utilisé ${openDyslexic ? "OpenDyslexic" : "Nunito"}`}
        width="size-600"
        variant="primary"
        style="outline"
        onPress={() => toggleTypo()}
      >
        <TextStyle />
      </Button>
    </>
  );
}
