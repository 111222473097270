import { useState, lazy, Suspense, memo } from "react";

import { Text, Flex, Content, Heading, Divider, Dialog } from "@adobe/react-spectrum";

import Loading from "./Loading";
const LazyUserProfilDescription = memo(lazy(() => import("./MemberData/ProfilDescription")));

function RealTimeCustomerProfile({ userId }) {
  const [getProfil, setGetProfil] = useState(false);

  // wainting for real time customer profile supabase
  // setGetProfil(false);

  return (
    <Dialog>
      <Heading>
        <Flex alignItems="center" gap="size-100">
          <Text>A propos de moi</Text>
        </Flex>
      </Heading>

      <Divider />

      <Content>
        <Suspense fallback={<Loading />}>
          <LazyUserProfilDescription userId={userId} />
        </Suspense>
      </Content>
    </Dialog>
  );
}

export default RealTimeCustomerProfile;
