import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { ListBox, Item, Section, Text } from "@adobe/react-spectrum";
import PageTag from "@spectrum-icons/workflow/PageTag";
import { publicSiteMap } from "../../../constants/vite_const_config";

const SiteMap = React.memo(function SiteMap() {
  const [selected, setSelected] = useState(new Set());
  const navigate = useNavigate();
  const options = [{ name: "Pages publiques", children: publicSiteMap }];

  const handleSelectionChange = useCallback(
    (selected) => {
      setSelected(selected);
      navigate("../" + selected.currentKey);
    },
    [navigate],
  );

  return (
    <ListBox
      aria-label="Liens"
      items={options}
      selectedKeys={selected}
      selectionMode="single"
      onSelectionChange={handleSelectionChange}
      width="100%"
    >
      {(item) => (
        <Section key={item.name} items={item.children} title={item.name}>
          {(item) => (
            <Item textValue={`${item.name} `}>
              <PageTag size="S" />
              <Text>{item.name}</Text>
              <Text slot="description">{item.description}</Text>
            </Item>
          )}
        </Section>
      )}
    </ListBox>
  );
});

SiteMap.propTypes = {
  publicSiteMap: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ),
};

export default SiteMap;
