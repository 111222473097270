const testimonials = Object.freeze([
  {
    name: "Didier",
    age: "64",
    message:
      "Grâce à Tramway, j'ai rencontré la femme de ma vie dans un cadre naturel et authentique, loin des plateformes de rencontres habituelles. Ce site écoresponsable m'a permis de créer des souvenirs qui durent. Merci du fond du cœur, Tramway.",
    avatar: "https://i.imgur.com/kJOwAdv.png",
  },
  {
    name: "Marco",
    age: "27",
    message:
      "Tramway m'a aidé à trouver une personne exceptionnelle. Grâce à vous, j'ai découvert non seulement l'amour, mais aussi une expérience unique dans un cadre durable et authentique. Un immense merci Vous êtes incroyables !",
    avatar: "https://i.imgur.com/kJOwAdv.png",
  },
  {
    name: "Floopy",
    age: "21",
    message:
      "Je ne suis plus seul grâce à vous, et je recommande ce site à tous ceux qui cherchent des connexions vraies et durables. Joyeuses fêtes à toute l'équipe !",
    avatar: "https://i.imgur.com/kJOwAdv.png",
  },
  {
    name: "Jacques",
    age: "59",
    message:
      "Je suis comblé de bonheur grâce à Tramway. J'ai rencontré quelqu'un de spécial avec qui je vis une belle complicité en harmonie avec la nature. Je n'aurais jamais imaginé une telle tournure dans ma vie. Merci infiniment Tramway pour cette belle aventure.",
    avatar: "https://i.imgur.com/kJOwAdv.png",
  },
  {
    name: "Paul",
    age: "41",
    message:
      "Tramway est un site magnifiquement conçu qui attire des personnes de grande qualité. Je recommande fortement cette plateforme à tous mes amis en quête de rencontres authentiques. Un grand merci à l'équipe Tramway pour cette expérience écoresponsable !",
    avatar: "https://i.imgur.com/kJOwAdv.png",
  },
  {
    name: "Julia",
    age: "53",
    message:
      "À 53 ans, après un AVC qui a bouleversé ma vie, Tramway m'a redonné espoir. Grâce à cette plateforme inclusive, j'ai retrouvé non seulement l'amour mais aussi la joie de vivre. Je ne vous remercierai jamais assez pour cette opportunité d'amour authentique.",
    avatar: "https://i.imgur.com/kJOwAdv.png",
  },
  {
    name: "Laura",
    age: "41",
    message:
      "Inscrite depuis à peine un mois, j'ai eu la chance de rencontrer quelqu'un qui a transformé ma vie. Tramway a su créer une plateforme où les connexions sincères prennent tout leur sens. Mille mercis à l'équipe pour ce merveilleux site !",
    avatar: "https://i.imgur.com/kJOwAdv.png",
  },
  {
    name: "Olivier",
    age: "32",
    message:
      "Je suis fou amoureux et bientôt marié à l'amour de ma vie, tout cela grâce à Tramway. Cette plateforme a su rendre cette aventure magique possible. Merci pour tout Tramway, et je t'aime, ma puce !",
    avatar: "https://i.imgur.com/kJOwAdv.png",
  },
  {
    name: "Peterheknows",
    age: "27",
    message:
      "Tramway a changé ma vie ! J'ai rencontré une femme incroyable, pleine de vie et dotée d'un humour pétillant. Nous partageons une complicité rare depuis six mois, et ce malgré les défis de la vie.",
    avatar: "https://i.imgur.com/kJOwAdv.png",
  },
]);

export { testimonials };
