import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Flex, Heading, InlineAlert, Content, Text, Divider, Item, ListBox } from "@adobe/react-spectrum";
import LinkOut from "@spectrum-icons/workflow/LinkOut";
import Circle from "@spectrum-icons/workflow/Circle";
import SMS from "@spectrum-icons/workflow/SMS";
import CallCenter from "@spectrum-icons/workflow/CallCenter";
import Hand1 from "@spectrum-icons/workflow/Hand1";
import Hand2 from "@spectrum-icons/workflow/Hand2";
import Hand3 from "@spectrum-icons/workflow/Hand3";
import StopCircle from "@spectrum-icons/workflow/StopCircle";
import FlagExclude from "@spectrum-icons/workflow/FlagExclude";
import Annotate from "@spectrum-icons/workflow/Annotate";
import Checkmark from "@spectrum-icons/workflow/Checkmark";
import Condition from "@spectrum-icons/workflow/Condition";

// Données des items
const precautionsAvantDeTendreLaMain = Object.freeze([
  {
    id: uuidv4(),
    textValue: "Votre profil",
    icon: <Checkmark />,
    title: "Un profil complet",
    description:
      "Assurez-vous que votre profil contient suffisamment d'informations sur vous-même, vos préférences et, si vous avez un style de vie particulier, assurez-vous de le mentionner. La confiance est mutuelle, donc votre profil devrait refléter qui vous êtes.",
  },
  {
    id: uuidv4(),
    textValue: "Lisez toutes les informations dans le profil d'une personne",
    icon: <Checkmark />,
    title: "Lisez toutes les informations dans le profil d'une personne",
    description:
      "Si quelqu'un a pris la peine de remplir son profil avec beaucoup d'informations, cela signifie que c'est important pour lui. Faites un effort pour tout lire.",
  },
  {
    id: uuidv4(),
    textValue: "Vérifiez la date d'inscription de la personne",
    icon: <Checkmark />,
    title: "Vérifiez la date d'inscription de la personne",
    description:
      "Malheureusement, certains membres créent de faux profils pour augmenter le nombre de leurs contacts. Vérifiez toujours la date d'inscription pour repérer d'éventuels faux profils.",
  },
]);

const precautionsLorsquOnTendLaMain = Object.freeze([
  {
    id: uuidv4(),
    textValue: "Restez sur les rails",
    icon: <Circle />,
    title: "Restez sur les rails",
    description:
      "Assurez-vous de rester sur la plateforme de Tramway pour toute communication. Si vous utilisez d'autres moyens de communication en dehors de la plateforme, Tramway ne sera pas en mesure de vous aider en cas de problème.",
  },
  {
    id: uuidv4(),
    textValue: "Assurez-vous d'être sur la même longueur d'onde",
    icon: <Circle />,
    title: "Assurez-vous d'être sur la même longueur d'onde",
    description:
      "Avant d'engager une conversation, assurez-vous de comprendre les intentions de l'autre personne et de partager les vôtres. Posez des questions spécifiques pour vous assurer que vous êtes sur la même longueur d'onde.",
  },
  {
    id: uuidv4(),
    textValue: "Il y a tant de gens, tant de personnalités",
    icon: <Circle />,
    title: "Il y a tant de gens, tant de personnalités",
    description:
      "Respectez la diversité des opinions et des personnalités. Si vous ne vous sentez pas à l'aise ou si vous avez des doutes, n'hésitez pas à annuler. Assurez-vous de connaître vos limites et de les communiquer clairement.",
  },
  {
    id: uuidv4(),
    textValue: "Soyez toujours respectueux et conscient des éventuelles différences (culturelles)",
    icon: <Circle />,
    title: "Soyez toujours respectueux et conscient des éventuelles différences (culturelles)",
    description: "Communiquez ouvertement pour éviter toute confusion et malentendu.",
  },
  {
    id: uuidv4(),
    textValue: "Comme la confiance, le respect va dans les deux sens",
    icon: <Circle />,
    title: "Comme la confiance, le respect va dans les deux sens",
    description: "Mettez-vous à la place de l'autre et vice versa pour favoriser une communication respectueuse.",
  },
  {
    id: uuidv4(),
    textValue: "Veillez à communiquer vos limites",
    icon: <Condition />,
    title: "Veillez à communiquer vos limites",
    description:
      "Assurez-vous de communiquer vos limites clairement, mais gardez à l'esprit que les différences de valeurs ne signifient pas qu'une personne a raison et l'autre tort.",
  },
  {
    id: uuidv4(),
    textValue: "Écrivez et partagez votre expérience sur la plateforme",
    icon: <Annotate />,
    title: "Écrivez et partagez votre expérience sur la plateforme",
    description: "Partagez vos expériences pour aider les autres membres et contribuer à améliorer la communauté.",
  },
  {
    id: uuidv4(),
    textValue: "Signaler toute expérience désagréable",
    icon: <FlagExclude />,
    title: "Signaler toute expérience désagréable",
    description:
      "Si vous avez vécu une expérience désagréable ou si quelqu'un a franchi vos limites, n'hésitez pas à contacter Tramway et à signaler le membre concerné via la modération.",
  },
  {
    id: uuidv4(),
    textValue: "Savoir dire NON",
    icon: <StopCircle />,
    title: "Savoir dire NON. Respect du consentement.",
    description:
      "Il est important de savoir dire NON lorsque vous ne vous sentez pas à l'aise ou lorsque vous ne souhaitez pas participer à une activité. Votre consentement est primordial pour votre bien-être et votre sécurité.",
  },
]);

const precautionsApresLaBalade = Object.freeze([
  {
    id: uuidv4(),
    textValue: "Informer un proche : Ayez un plan B",
    icon: <Hand1 />,
    title: "Informer un proche : Ayez un plan B",
    description: "Assurez-vous d'avoir un plan de secours en cas d'urgence et informez un proche de votre rencontre.",
  },
  {
    id: uuidv4(),
    textValue: "Choisir un lieu approprié",
    icon: <Hand2 />,
    title: "Choisir un lieu public fréquenté",
    description:
      "Optez pour un lieu public. Evitez d'être isolé. Un lieu fréquenté où vous vous sentez à l'aise et que vous connaissez bien.",
  },
  {
    id: uuidv4(),
    textValue: "Contacter les autorités si nécessaire",
    icon: <Hand3 />,
    title: "Contacter les autorités si nécessaire",
    description: "Renseignez-vous sur les moyens de contacter les autorités locales en cas de besoin ou d'urgence.",
  },
]);

const aideCritique = Object.freeze([
  {
    id: uuidv4(),
    textValue: "Signaler un contenu illicite sur PHAROS",
    icon: <LinkOut />,
    title: "Portail PHAROS",
    href: "https://www.internet-signalement.gouv.fr/PharosS1/",
    description:
      "Lien externe vers le portail officiel Français des signalement de contenus illicites : Violence, mise en danger des personnes, menace ou apologie du terrorisme, injure ou diffamation, incitation à la haine raciale ou discrimination, atteintes aux mineurs.",
  },
  {
    id: uuidv4(),
    textValue: "Appeler le 112 : Toutes les urgences.",
    icon: <CallCenter />,
    title: "Appeler le 112 : Toutes les urgences.",
    href: "tel:112",
    description:
      "Partout dans l'Union européenne, gratuit même depuis un GSM, grâce au 112, vous pouvez joindre ce service qui vous redirigera vers les urgences qui vous concernent.",
  },
  {
    id: uuidv4(),
    textValue: "Envoyer un SMS au 114 : Urgence.",
    icon: <SMS />,
    title: "Envoyer un SMS au 114 : Urgence accessible.",
    href: "sms:114",
    description:
      "Le 114 est le service public gratuit Français réservé aux personnes sourdes, sourdaveugles, malentendantes et aphasiques, pour toutes les urgences, 24h/24, 7j/7.",
  },
]);

export default function Aide() {
  const [, setSelected] = useState(new Set());

  return (
    <Flex direction="column" maxWidth="100%">
      <Text>
        La confiance est un pilier essentiel du réseau Tramway. Nous encourageons nos membres à explorer, à rencontrer
        de nouvelles personnes, à partager des expériences, à apprendre et à créer des liens les uns avec les autres.
        Rencontrer de nouvelles personnes est toujours passionnant, et grâce à notre plateforme, de véritables amitiés
        se sont déjà formées. Nos cercles d&apos;intérêt offrent un espace où vous pouvez facilement trouver des
        personnes partageant vos idées. Votre profil et vos activités reflètent qui vous êtes. Bien que nous préférions
        accorder notre confiance aux bonnes personnes, notre plateforme est ouverte à tous et nous ne pouvons donc pas
        garantir une sécurité absolue. Cependant, il existe quelques mesures simples que vous pouvez prendre pour
        assurer votre sécurité sur notre plateforme.
      </Text>

      <Heading id="host-info" marginTop="size-600" marginBottom="size-10" level={2}>
        Avant de tendre la main
      </Heading>

      <Divider marginBottom="size-400" />

      <ListBox
        selectionMode="multiple"
        marginY="size-100"
        aria-label="Quelques précautions avant de tendre la main"
        items={precautionsAvantDeTendreLaMain}
        onSelectionChange={(selected) => setSelected(selected)}
      >
        {(item) => (
          <Item key={item?.id} textValue={item?.textValue}>
            {item?.icon}
            <Text>{item?.title}</Text>
            <Text slot="description">{item?.description}</Text>
          </Item>
        )}
      </ListBox>

      <Heading id="host-info" marginTop="size-600" marginBottom="size-10" level={2}>
        Lorsque l&apos;on tend la main
      </Heading>

      <Divider marginBottom="size-400" />

      <ListBox
        selectionMode="multiple"
        marginY="size-100"
        aria-label="Quelques précautions lorsque l'on tend la main"
        items={precautionsLorsquOnTendLaMain}
        onSelectionChange={(selected) => setSelected(selected)}
      >
        {(item) => (
          <Item key={item?.id} textValue={item?.textValue}>
            {item?.icon}
            <Text>{item?.title}</Text>
            <Text slot="description">{item?.description}</Text>
          </Item>
        )}
      </ListBox>

      <Heading id="host-info" marginTop="size-600" marginBottom="size-10" level={2}>
        Après la balade en Tramway
      </Heading>

      <Divider marginBottom="size-400" />

      <InlineAlert variant="notice">
        <Heading>
          Tramway • courant alternatif <span title="Marque déposée">&#x00AE;</span>
        </Heading>
        <Content>
          Tramway ne peut vérifier l&apos;identité de chaque Membre et ne peut être tenue responsable d&apos;éventuelles
          inexactitudes. L&apos;objectif de Tramway n&apos;est pas d&apos;organiser des rencontres physiques ni
          d&apos;être courtier matrimonial ni d&apos;être un conseille en vue d&apos;une union. Tramway est un{" "}
          <span lang="en" title="SAAS : Software As A Service">
            Logiciel en tant que service
          </span>{" "}
          permettant à ses Membres de faire des rencontres virtuelles dans un environnement serein et convivial. Tramway
          n&apos;assume aucune obligation de résultat et se décharge de toute responsabilité quant aux rencontres
          physiques entre ses Membres.
          <Heading>Néanmoins, Tramway vous invite à prendre quelques précautions en cas de rencontre physique</Heading>
          <ListBox
            selectionMode="multiple"
            marginY="size-100"
            aria-label="Néanmoins, Tramway vous invite à prendre quelques précautions en cas de rencontre physique"
            items={precautionsApresLaBalade}
            onSelectionChange={(selected) => setSelected(selected)}
          >
            {(item) => (
              <Item key={item?.id} textValue={item.textValue}>
                {item?.icon}
                <Text>{item?.title}</Text>
                <Text slot="description">{item?.description}</Text>
              </Item>
            )}
          </ListBox>
        </Content>
      </InlineAlert>

      <Heading id="host-info" marginTop="size-600" marginBottom="size-10" level={2}>
        Vous avez besoin d&apos;aide dans une situation critique ?
      </Heading>

      <Divider marginBottom="size-400" />
      <ListBox
        selectionMode="multiple"
        marginY="size-100"
        aria-label="Vous avez besoin d'aide dans une situation critique ?"
        items={aideCritique}
        onSelectionChange={(selected) => setSelected(selected)}
      >
        {(item) => (
          <Item
            key={item?.id}
            textValue={item?.textValue}
            isQuiet
            href={item?.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item?.icon}
            <Text>{item?.title}</Text>
            <Text slot="description">{item?.description}</Text>
          </Item>
        )}
      </ListBox>
    </Flex>
  );
}
