import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserSession from "../contexts/UserSession";
import UserAccessibility from "../contexts/UserAccessibility";

import GetMenuIcon from "../config/GetMenuIcon";
import { NAV, MENU_NAV } from "../constants/nav";
import { Text, MenuTrigger, Menu, Section, Item, Tooltip, TooltipTrigger, ActionButton } from "@adobe/react-spectrum";

import ShowMenu from "@spectrum-icons/workflow/ShowMenu";
function NavItems() {
  const { open, setOpen } = useContext(UserAccessibility);
  let [openMenu, setOpenMenu] = useState(false);

  const [selected, setSelected] = useState("creerCompte");
  const { sessionRole } = useContext(UserSession);
  const navigate = useNavigate();

  const renderNavSectionItems = (menuItems) => {
    return menuItems.map(({ key, textValue, label }) => (
      <Item key={key} textValue={textValue}>
        {GetMenuIcon(key, label, selected.currentKey)}
        <Text UNSAFE_style={{ fontFamily: "Nunito ExtraBold" }}>{textValue}</Text>
      </Item>
    ));
  };

  return (
    <TooltipTrigger>
      <MenuTrigger isOpen={openMenu || open} onOpenChange={setOpenMenu || setOpen} direction="top">
        <ActionButton
          UNSAFE_style={{
            transform: "rotate(-2deg)",
            borderRadius: "20% 25% 20% 24%",
          }}
          aria-label="Menu de navigation"
        >
          <ShowMenu />
        </ActionButton>

        <Menu
          onAction={(key) => {
            navigate(key);
          }}
          selectionMode="single"
          selectedKeys={selected}
          onSelectionChange={setSelected}
        >
          <Section title="TRAMWAY">
            {renderNavSectionItems([
              {
                key: sessionRole !== "authenticated" ? NAV.creerCompte : NAV.profile,
                textValue: sessionRole !== "authenticated" ? MENU_NAV.creerCompte : MENU_NAV.profile,
                label: sessionRole !== "authenticated" ? MENU_NAV.creerCompte : MENU_NAV.profile,
              },
            ])}
          </Section>
          <Section title="À PROPOS">
            {renderNavSectionItems([
              { key: NAV.avis, textValue: MENU_NAV.avis, label: MENU_NAV.avis },
              { key: NAV.manifesto, textValue: MENU_NAV.manifesto, label: MENU_NAV.manifesto },
              { key: NAV.feed, textValue: MENU_NAV.feed, label: MENU_NAV.suivre },
            ])}
          </Section>
          <Section title="INFORMATIONS">
            {renderNavSectionItems([
              { key: NAV.contact, textValue: MENU_NAV.contact, label: MENU_NAV.contact },
              { key: NAV.aide, textValue: MENU_NAV.aide, label: MENU_NAV.aide },
              { key: NAV.mentionsLegales, textValue: MENU_NAV.mentionsLegales, label: MENU_NAV.mentionsLegales },
            ])}
          </Section>
          <Section height="auto" title="ACCESSIBILITÉ NUMÉRIQUE">
            {renderNavSectionItems([
              { key: NAV.accessibilite, textValue: MENU_NAV.accessibilite, label: MENU_NAV.accessibilite },
            ])}
          </Section>
        </Menu>
      </MenuTrigger>
      <Tooltip>Menu</Tooltip>
    </TooltipTrigger>
  );
}
export default NavItems;
