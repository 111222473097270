import { useState, useEffect } from "react";
import { Flex, View } from "@adobe/react-spectrum";
import UserToggleLightThemeButton from "../../components/UserToggleLightThemeButton";
import Header from "../../components/LandingPage/Header/Header";
import HeroSection from "../../components/LandingPage/HeroSection/HeroSection";
import FeaturesBenefits from "../../components/LandingPage/FeaturesBenefits/FeaturesBenefits";
import MoreSocialProof from "../../components/LandingPage/MoreSocialProof/MoreSocialProof";
import Footer from "../../components/PublicFooter";

// Hook personnalisé pour la gestion de la taille de l'écran
function useIsSmallScreen() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => setIsSmallScreen(window.innerWidth < 1024);
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);
    return () => window.removeEventListener("resize", checkScreenWidth);
  }, []);

  return isSmallScreen;
}

export default function Landing() {
  const isSmallScreen = useIsSmallScreen();

  return (
    <>
      {/* Contenu principal */}
      <View colorVersion="6" backgroundColor="gray-100">
        <Flex direction="column" justifyContent="center">
          <Header />
          <HeroSection />
          <FeaturesBenefits isSmallScreen={isSmallScreen} />
          <MoreSocialProof isSmallScreen={isSmallScreen} />
          <Footer />
        </Flex>
      </View>

      {/* Bouton pour le thème */}
      <FloatingThemeButton />
    </>
  );
}

// Composant de bouton flottant pour basculer le thème
const FloatingThemeButton = () => (
  <View
    zIndex="5"
    position="fixed"
    right="size-200"
    bottom="size-200"
    backgroundColor="gray-200"
    marginY="size-250"
    borderRadius="large"
  >
    <Flex direction="column" gap="size-100">
      <UserToggleLightThemeButton />
    </Flex>
  </View>
);
