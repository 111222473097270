import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { Text, ListBox, Item, Button } from "@adobe/react-spectrum";
import Flash from "@spectrum-icons/workflow/Heart";
import Courrier from "@spectrum-icons/workflow/Mailbox";
import Visite from "@spectrum-icons/workflow/SentimentPositive";

const NotificationList = memo(({ notificationsData }) => {
  const renderItem = useCallback((item) => {
    if (item.count <= 0) return null;

    const iconMap = {
      Visite: <Visite size="S" color="positive" />,
      Flash: <Flash size="S" color="negative" />,
      Message: <Courrier size="S" color="informative" />,
    };

    return (
      <Item key={item.id} textValue={item.title}>
        {iconMap[item.name] || null}
        <Text>{item.body}</Text>
      </Item>
    );
  }, []);

  return (
    <ListBox selectionMode="single" aria-label="notifications" items={notificationsData}>
      {renderItem}
    </ListBox>
  );
});

NotificationList.propTypes = {
  notificationsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default NotificationList;
