import { useContext } from "react";
import TextSizeAdd from "@spectrum-icons/workflow/TextSizeAdd";
import TextSize from "@spectrum-icons/workflow/TextSize";
import { Button, useProvider } from "@adobe/react-spectrum";
import { SCALE } from "../constants/theme";
import UserAccessibility from "../contexts/UserAccessibility";

export default function UserToggleTypoButton() {
  const { scale } = useProvider();
  const { toggleScale } = useContext(UserAccessibility);

  const getButtonContent = () => {
    const icon = scale === SCALE.large ? <TextSize /> : <TextSizeAdd />;

    return <>{icon}</>;
  };

  return (
    <>
      <Button
        width="size-600"
        variant="primary"
        aria-label={scale === SCALE.large ? "Petite typographie" : "Grande typographie"}
        style="outline"
        onPress={() => toggleScale()}
      >
        {getButtonContent()}
      </Button>
    </>
  );
}
