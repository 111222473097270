import "../global.js";
import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import GlobalProvider from "./providers/GlobalProvider.jsx";
import "./style.css";

// In Google Analytics 4, IP anonymization is not necessary since IP addresses are not logged or stored.
import ReactGA from "react-ga4";
ReactGA.initialize([
  {
    trackingId: "G-KBY3PBPVNE",
    gaOptions: { anonymize_ip: true },
    gtagOptions: { anonymize_ip: true },
  },
]);

const rootElement = document.getElementById("root");
const reactNode = (
  <React.StrictMode>
    <BrowserRouter>
      <GlobalProvider>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </GlobalProvider>
    </BrowserRouter>
  </React.StrictMode>
);

ReactDOM.createRoot(rootElement).render(reactNode);

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
