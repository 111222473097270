import { useState, memo } from "react";
import { glossaire } from "../constants/glossaire";
import { Item, ListBox, Text, View } from "@adobe/react-spectrum";
import ChevronRight from "@spectrum-icons/workflow/ChevronRight";

const Glossaire = memo(() => {
  const [word, setWord] = useState(new Set());

  return (
    <>
      <View key={word}>
        <ListBox
          selectionMode="single"
          aria-label="Pick an item"
          items={glossaire}
          onSelectionChange={(selected) => setWord(selected)}
        >
          {(item) => (
            <Item textValue={item.title}>
              <ChevronRight />
              <Text>{item.title}</Text>
              <Text slot="description">{item.text}</Text>
            </Item>
          )}
        </ListBox>
      </View>
    </>
  );
});

Glossaire.displayName = "Glossaire";
export default Glossaire;
