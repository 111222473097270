import useLazyLoadWithLayout from "../hooks/useLazyLoadWithLayout";

import Accueil from "../pages/private/Accueil";
import Circles from "../pages/private/Cercles";
import Flash from "../pages/private/Flash";
import Messages from "../pages/private/Messages";
import Profile from "../pages/private/Profil";
import PrivatePage from "../pages/private/PrivatePage";

function GetPrivateRoutes() {
  const accueilLazy = useLazyLoadWithLayout(Accueil);
  const circlesLazy = useLazyLoadWithLayout(Circles);
  const flashLazy = useLazyLoadWithLayout(Flash);
  const messagesLazy = useLazyLoadWithLayout(Messages);
  const profileLazy = useLazyLoadWithLayout(Profile);
  const privatePageLazy = useLazyLoadWithLayout(PrivatePage);

  return {
    accueilLazy,
    flashLazy,
    circlesLazy,
    messagesLazy,
    profileLazy,
    privatePageLazy,
  };
}

export default GetPrivateRoutes;
