import { lazy, Suspense, memo, useState } from "react";
import Loading from "../../components/Loading";
import ProfilTabs from "../../components/MemberData/ProfilTabs";
import RealTimeCustomerProfile from "../../components/RealTimeCustomerProfile";
import PrivateLayout from "../../components/Layout/PrivateLayout";
import PinOff from "@spectrum-icons/workflow/PinOff";
import {
  ActionButton,
  Button,
  Flex,
  DialogTrigger,
  Text,
  Tooltip,
  TooltipTrigger,
  DialogContainer,
  Avatar,
  ActionGroup,
  Item,
  ProgressBar,
  ListView,Image
} from "@adobe/react-spectrum";

export default function Profil() {
  let [isOpen, setOpen] = useState(false);
  let [actionKey, setActionKey] = useState('');
  return (
    <PrivateLayout>
      <Flex justifyContent="space-between">
        {/* <Flex alignItems="center" gap="size-100">
          <DialogTrigger type="tray">
            <TooltipTrigger>
              <Button
                height="size-450"
                isQuiet
                variant="secondary"
                style="fill"
                aria-label="Ce qui me meut"
                marginY="size-200"
              >
                <Avatar
                  marginEnd="size-100"
                  size="avatar-size-400"
                  src="https://i.imgur.com/kJOwAdv.png"
                  alt="default Adobe avatar"
                />
                <Text>Monter mon profil</Text>
              </Button>
              <Tooltip showIcon="true">A propos de moi</Tooltip>
            </TooltipTrigger>
            <RealTimeCustomerProfile />
          </DialogTrigger>
        </Flex> */}
<Flex  marginBottom="size-400"    width="100%"
 alignItems="start" gap="size-100" direction="column">
        <ListView
          selectionMode="single"
          aria-label="Mon profil"
          width="100%"
          density="spacious"
          isQuiet
          selectionStyle="highlight"
        >
          <Item textValue="Mon profil">
          <Image src="https://random.dog/1a0535a6-ca89-4059-9b3a-04a554c0587b.jpg" alt="Shiba Inu with glasses" />
            {" "}
            <Text>Pseudo, Age, Ville</Text>
            <Text slot="description">Bio lalallaaaaaaaaaaa</Text>{" "}

              <ActionButton aria-label="Prévisualiser"
            >
                <PinOff />

              </ActionButton>

          </Item>
        </ListView>


        <ProgressBar width="100%" aria-label="Complétion du profil" isIndeterminate={false} value={5} maxValue={100} />

        </Flex>



        <DialogContainer type="fullscreenTakeover" onDismiss={() => setOpen(false)}>
          {isOpen && (
            <Suspense fallback={<Loading />}>
              <SetlazySettingsDialog />
            </Suspense>
          )}
        </DialogContainer>
      </Flex>

      <ProfilTabs />
    </PrivateLayout>
  );
}
