import useSWR from "swr";
import { supabase } from "../../../supabaseClient";

// Fetch all circles
const fetchCircles = async () => {
  const { data, error } = await supabase.rpc("get_circles_with_subscriptions");
  if (error) {
    console.error("Error fetching circles:", error);
    throw new Error(error.message);
  }
  return data;
};

// Fetch subscribed circles for a specific user
const fetchUserCircles = async (userId) => {
  const { data, error } = await supabase.rpc("get_user_circle_subscriptions", { p_user_id: userId });
  if (error) {
    console.error("Error fetching user circles:", error);
    throw new Error(error.message);
  }
  return data;
};

export const useCircles = (userId) => {
  const fetchAllData = async () => {
    if (!userId) {
      // Only fetch all circles if no userId is provided
      const circles = await fetchCircles();
      return { circles, subscribedCircles: null };
    } else {
      // Fetch both all circles and user subscribed circles
      const [circles, subscribedCircles] = await Promise.all([fetchCircles(), fetchUserCircles(userId)]);
      return { circles, subscribedCircles };
    }
  };

  // Use `null` as key when userId is not provided, which skips the SWR fetch
  const { data, error } = useSWR(userId ? ["circles", userId] : "circles", fetchAllData);

  return {
    circles: data?.circles,
    subscribedCircles: data?.subscribedCircles,
    isLoading: !error && !data,
    isError: !!error,
  };
};
