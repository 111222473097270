import { memo } from "react";
import PropTypes from "prop-types";
import { Heading, Link } from "@adobe/react-spectrum";

const HoverLink = memo(({ picture, photoPortfolio, photoAuthor, isHovered }) => {
  if (!isHovered) return null;

  const href = picture ? "https://picsum.photos/" : `${photoPortfolio}?utm_source=Tramway-609736&utm_medium=referral`;

  return (
    <Link
      position="absolute"
      bottom="39%"
      right="3%"
      variant="secondary"
      isQuiet
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Heading margin="size-0" level={5}>
        {photoAuthor ? `@${photoAuthor}` : ""}
      </Heading>
    </Link>
  );
});

HoverLink.propTypes = {
  photoAuthor: PropTypes.string,
  photoPortfolio: PropTypes.string,
  picture: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isHovered: PropTypes.bool.isRequired,
};

HoverLink.displayName = "HoverLink";

export default HoverLink;
