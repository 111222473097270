import PropTypes from "prop-types";
import UserSession from "../contexts/UserSession";
import { useUserSession } from "../hooks/useUserSession";

export const SessionProvider = ({ children }) => {
  const session = useUserSession();

  return <UserSession.Provider value={session}>{children}</UserSession.Provider>;
};

SessionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SessionProvider;
