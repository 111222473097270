import { memo } from "react";
import UserToggleLightThemeButton from "../../../components/UserToggleLightThemeButton";
import UserToggleTypoButton from "../../../components/UserToggleTypoButton";
import UserToggleOpenDyslexic from "../../../components/UserToggleOpenDyslexic";
import { Flex, Heading, Well, Text, Divider, Keyboard, Item, ListBox, Section } from "@adobe/react-spectrum";
import LinkOut from "@spectrum-icons/workflow/LinkOut";

// Memoized components for better performance
const MemoizedUserToggleLightThemeButton = memo(UserToggleLightThemeButton);
const MemoizedUserToggleTypoButton = memo(UserToggleTypoButton);

export default function Accessibilite() {
  return (
    <Flex direction="column" maxWidth="100%">
      <Text>
        Tramway est avant tout un projet simple et humain ; fort et soudé et il nous tient à cœur de rester une
        entreprise à taille humaine, de préférer l&apos;expertise des uns et des autres au services des usagers.
      </Text>

      <Heading marginTop="size-600" marginBottom="size-10" level={2}>
        Fonctionnalités du site
      </Heading>
      <Divider marginBottom="size-400" />

      <Well>
        <Heading level={3}>Personnalisation de l’affichage</Heading>
        <Flex margin="size-300" gap="size-100" wrap>
          <MemoizedUserToggleLightThemeButton />
          <MemoizedUserToggleTypoButton />
          <UserToggleOpenDyslexic />
        </Flex>
      </Well>

      <Well>
        <Heading level={3}>Liens d’accès rapide</Heading>
        <ListBox width="100%" aria-label="Raccourcis clavier" selectionMode="single">
          <Section title="Raccourcis clavier">
            <Item textValue="rechercher">
              <Text>
                <Keyboard>alt+T</Keyboard>
              </Text>
              <Text slot="description">Vous permet d&apos;ouvrir directement le menu de navigation.</Text>
            </Item>
            <Item textValue="rechercher">
              <Text>
                <Keyboard>alt+R</Keyboard>
              </Text>
              <Text slot="description">Vous permet de basculer directement en mode sombre ou clair.</Text>
            </Item>
            <Item textValue="contenu">
              <Text>
                <Keyboard>alt+A</Keyboard>
              </Text>
              <Text slot="description">Vous permet d’atteindre directement le contenu principal de la page.</Text>
            </Item>
            <Item textValue="mode sombre ou clair">
              <Text>
                <Keyboard>alt+M</Keyboard>
              </Text>
              <Text slot="description">Vous permet de naviguer directement vers la page contact.</Text>
            </Item>
          </Section>
        </ListBox>
      </Well>

      <Heading marginTop="size-600" marginBottom="size-10" level={2}>
        Amélioration et contact
      </Heading>
      <Divider marginBottom="size-400" />
      <Text>Vous pouvez contribuer à améliorer l’accessibilité du site.</Text>
      <br />
      <ListBox width="100%" marginY="size-100" aria-label="Liens externes ouvrant une nouvelle fenêtre">
        <Item
          aria-label="Envoyer un mail à onpapote@tramway.life"
          isQuiet
          href="mailto:onpapote@tramway.life"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkOut />
          <Text>Envoyer un mail à onpapote@tramway.life</Text>
        </Item>
      </ListBox>
      <Text>
        N’hésitez pas à nous signaler les problèmes éventuels que vous rencontrez et qui vous empêchent d’accéder à un
        contenu ou à une fonctionnalité du site.
      </Text>

      <Heading marginTop="size-600" marginBottom="size-10" level={2}>
        Défenseur des Droits
      </Heading>
      <Divider marginBottom="size-400" />
      <Text>
        Si vous constatez un défaut d’accessibilité qui vous empêche d’accéder à un contenu ou une fonctionnalité du
        site, que vous nous en informez et que vous ne parvenez pas à obtenir une réponse rapide de notre part, vous
        êtes en droit de faire parvenir vos doléances ou demande de saisine au Défenseur des Droits. Plusieurs moyens
        sont à votre disposition :
      </Text>
      <br />
      <ListBox width="100%" marginY="size-100" aria-label="Liens externes ouvrant une nouvelle fenêtre">
        <Item
          textValue="Ecrire au Défenseur des droits"
          href="https://formulaire.defenseurdesdroits.fr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkOut />
          <Text>Ecrire au Défenseur des droits</Text>
        </Item>
        <Item
          textValue="Contacter le délégué du Défenseur des droits dans votre région"
          href="https://www.defenseurdesdroits.fr/saisir/delegues"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkOut />
          <Text>Contacter le délégué du Défenseur des droits dans votre région</Text>
        </Item>
        <Item
          textValue="Appeler au numéro français suivant : +33 9 69 39 00 00"
          aria-label="Envoyer un mail à : onpapote@tramway.life"
          isQuiet
          href="tel:+33969390000"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkOut />
          <Text>Appeler au : +33 9 69 39 00 00</Text>
        </Item>
      </ListBox>

      <Well role="region" aria-labelledby="wellLabel" marginY="size-100">
        <Heading level={3} id="wellLabel">
          Envoyer un courrier postal sans affranchissement
        </Heading>
        <Text>
          Le Défenseur des droits <br /> Libre réponse 71120 <br />
          75342 Paris CEDEX 07
        </Text>
        <br />
      </Well>
    </Flex>
  );
}
