import PropTypes from "prop-types";
import { CONTACT_FORM_KEY } from "../constants/contact_form_options";

import Help from "@spectrum-icons/workflow/Help";
import PeopleGroup from "@spectrum-icons/workflow/PeopleGroup";
import NewsAdd from "@spectrum-icons/workflow/NewsAdd";
import Heart from "@spectrum-icons/workflow/Heart";
import DataCheck from "@spectrum-icons/workflow/Data";
import Shop from "@spectrum-icons/workflow/Shop";
import Alert from "@spectrum-icons/workflow/AlertCircle";
import Labels from "@spectrum-icons/workflow/Labels";

export default function GetContactIcon(key, label) {
  switch (key) {
    case CONTACT_FORM_KEY.SAV:
      return <Shop aria-label={label} />;
    case CONTACT_FORM_KEY.PARTNERSHIP:
      return <PeopleGroup aria-label={label} color="positive" />;
    case CONTACT_FORM_KEY.PRESS:
      return <NewsAdd aria-label={label} color="informative" />;
    case CONTACT_FORM_KEY.SUCCESS_STORIES:
      return <Heart aria-label={label} color="negative" />;
    case CONTACT_FORM_KEY.TERMS:
      return <DataCheck aria-label={label} />;
    case CONTACT_FORM_KEY.OTHER:
      return <Labels aria-label={label} color="notice" />;
    case CONTACT_FORM_KEY.SUPPORT:
      return <Help aria-label={label} />;
    case CONTACT_FORM_KEY.MODERATION:
      return <Alert aria-label="Informative Alert" />;
    default:
      return "";
  }
}

GetContactIcon.propTypes = {
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
