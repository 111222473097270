import { useNavigate } from "react-router-dom";
import { PUBLIC_ROUTES } from "../../../constants/publicroutes";
import { Tooltip, Text, TooltipTrigger, Button } from "@adobe/react-spectrum";
import Heart from "@spectrum-icons/workflow/Heart";

function CTAWithReassurance() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`../${PUBLIC_ROUTES.creerCompte}`);
  };

  return (
    <>
      <TooltipTrigger>
        <Button
          variant="accent"
          style="outline"
          UNSAFE_style={{
            transform: "rotate(-2deg)",
            borderRadius: "20% 25% 20% 24%",
          }}
          aria-label="Quitter le bitume et s'inscrire"
          onPress={handleNavigate}
        >
          <Heart />
          <Text>Quitter le bitume</Text>
        </Button>
        <Tooltip>Inscription</Tooltip>
      </TooltipTrigger>
    </>
  );
}

export default CTAWithReassurance;
