import React, { useState } from "react";
import { Button } from "@adobe/react-spectrum";
import { FileTrigger } from "react-aria-components";
import { supabase } from "../../supabaseClient";

const UploadAudio = ({ conversationId, senderId, receiverId }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (selectedFiles) => {
    const selectedFile = selectedFiles[0];
    setFile(selectedFile);
    // Construire le nom du fichier basé sur l'ID de la conversation et les IDs de l'expéditeur et du destinataire
    setFileName(`${conversationId}_${senderId}_${receiverId}/${selectedFile.name}`);
    setError(null);
  };

  const uploadFile = async () => {
    if (!file) return;

    setLoading(true);
    setError(null);

    try {
      const { error: uploadError } = await supabase.storage
        .from("audio") // Utilisation du bucket "audio"
        .upload(fileName, file, {
          cacheControl: "3600",
          upsert: false, // Remplacer le fichier s'il existe déjà
        });

      if (uploadError) throw uploadError;

      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <FileTrigger acceptedFileTypes={["audio/mpeg", "audio/wav", "audio/ogg"]} onSelect={handleFileChange}>
        <Button variant="primary">Sélectionner un fichier audio</Button>
      </FileTrigger>

      {file && (
        <div>
          <p>Fichier sélectionné : {fileName}</p>
          <Button onPress={uploadFile} isDisabled={loading}>
            {loading ? "Téléchargement..." : "Télécharger"}
          </Button>
        </div>
      )}

      {error && <p style={{ color: "red" }}>{error}</p>}

      {!loading && !error && file && <p>Téléchargement terminé avec succès !</p>}
    </div>
  );
};

export default UploadAudio;
