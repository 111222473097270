import { InlineAlert, Heading, Content, Flex, View } from "@adobe/react-spectrum";
import LegalSummary from "../../../components/LegalSummary";
import LegalContent from "../../../components/LegalContent";

export default function MentionsLegales() {
  return (
    <>
      <InlineAlert variant="notice">
        <Heading>
          Tramway • courant alternatif <span title="Marque déposée">&#x00AE;</span>{" "}
        </Heading>

        <Content>
          Une plateforme de rencontres pour les personnes physiques disposant de la pleine capacité juridique et majeur.
          Utilisez la plateforme Tramway de manière légale, consenti, authentique et positive.
        </Content>
      </InlineAlert>

      <Flex maxWidth="90%" marginTop="size-300" direction={{ base: "column", S: "column", M: "column", L: "row" }}>
        <View width={{ base: "100%", S: "100%", M: "100%", L: "30%" }}>
          <Content maxWidth="100%">
            <LegalSummary />
          </Content>
        </View>
        <View width={{ base: "100%", S: "100%", M: "100%", L: "70%" }}>
          <Content maxWidth="100%">
            <LegalContent />
          </Content>
        </View>
      </Flex>
    </>
  );
}
