import { Flex, View, Image } from "@adobe/react-spectrum";
import ValueProposition from "./ValueProposition";
import CTAWithReassurance from "./CTAWithReassurance";
import Subtitle from "./Subtitle";
import SocialProof from "./SocialProof";
import { memo } from "react";
import useUnsplashPhoto from "../Hooks/useUnsplashPhoto";

const HeroSection = memo(function HeroSection() {
  const { photoUrl, portfolioUrl, name, altDescription } = useUnsplashPhoto("EuCll-F5atI", "raw");

  return (
    <>
      <View position="relative" maxWidth="100%">
        {/* Image de fond */}
        <Image maxWidth="100%" alt={altDescription} src={photoUrl} objectFit="cover" />

        {/* Texte toujours en haut, parfaitement centré */}
        <Flex justifyContent="center" position="absolute" top="10%" width="100%">
          <ValueProposition portfolioUrl={portfolioUrl} name={name} />
        </Flex>
      </View>

      {/* Contenu principal */}
      <View paddingStart="size-500" paddingEnd="size-500" paddingTop="size-500" paddingBottom="size-500">
        <Flex alignItems="center" direction="column">
          <CTAWithReassurance />
          <Subtitle />
        </Flex>
        <SocialProof />
      </View>
    </>
  );
});

export default HeroSection;
