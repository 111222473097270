import { useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  Form,
  TextField,
  Heading,
  ButtonGroup,
  Checkbox,
  Button,
  TextArea,
  ComboBox,
  Item,
  Text,
  Flex,
} from "@adobe/react-spectrum";
import UserSession from "../contexts/UserSession";
import { CONTACT_FORM_OPTIONS } from "../constants/contact_form_options";
import GetContactIcon from "../config/GetContactIcon";
import InlineAlertForm from "./InlineAlertForm";
import useEmailSender from "../hooks/useEmailSender";
import { useUsername } from "../services/rpc_supabase/profiles/useUsername";

const ContactUs = () => {
  const { session, sessionEmail } = useContext(UserSession);
  const [option, setOption] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [formEmail, setFormEmail] = useState("");
  const [formPseudo, setFormPseudo] = useState("");
  const [formTextarea, setFormTextArea] = useState("");
  const { sendEmail, isLoading, showMessage, variant, message, setShowMessage } = useEmailSender();
  const { username, error, isLoading: userNameLoading } = useUsername(session?.user?.id);

  const handleSubmit = (e) => {
    sendEmail(e, formEmail);
    setDisabled(true);
  };

  const handleReset = () => {
    setShowMessage(false);
    setDisabled(false);
  };

  return (
    <Flex
      margin="size-250"
      direction="column"
      alignItems="center"
      justifyContent="center"
      marginBottom="size-300"
      maxWidth="size-6000"
    >
      <Heading level={3}>
        Vous avez des questions ? Laissez-nous votre message, nous nous efforçons de répondre dans les deux jours
        ouvrés. Les champs précédés d’un * sont obligatoires.
      </Heading>
      <Form isDisabled={error} onSubmit={handleSubmit} onReset={handleReset} validationBehavior="native">
        {showMessage && <InlineAlertForm messageVariant={variant} message={message} />}
        <ComboBox
          isQuiet
          autofocus={true}
          name="user_object"
          isRequired
          necessityIndicator="icon"
          label="Raison"
          defaultItems={CONTACT_FORM_OPTIONS}
          selectedKey={option}
          onSelectionChange={setOption}
          errorMessage={({ validationDetails }) =>
            validationDetails.valueMissing ? "Veuillez sélectionner la raison de votre message." : ""
          }
        >
          {(item) => (
            <Item textValue={item.id} key={item.id}>
              {GetContactIcon(item.id, item.name)}
              <Text>{item.name}</Text>
            </Item>
          )}
        </ComboBox>

        <TextField
          autoComplete="given-name"
          isQuiet
          minLength={3}
          maxLength={42}
          value={userNameLoading ? "..." : username || formPseudo}
          isReadOnly={!!username}
          onChange={setFormPseudo}
          label="Nom"
          name="user_name"
          type="text"
          isRequired
          necessityIndicator="icon"
          autoCapitalize="off"
          errorMessage={({ validationDetails }) =>
            validationDetails.valueMissing ? "Veuillez indiquer votre nom." : ""
          }
        />

        <TextField
          autoComplete="email"
          isQuiet
          value={sessionEmail ? sessionEmail : formEmail}
          isReadOnly={!!sessionEmail}
          inputMode="email"
          onChange={setFormEmail}
          label="Email"
          name="user_email"
          isRequired
          necessityIndicator="icon"
          type="email"
          aria-label="email"
          errorMessage={({ validationDetails }) =>
            validationDetails.valueMissing
              ? "Veuillez indiquer votre email. La forme de votre email doit être de type xxx@xxx.xxx"
              : ""
          }
        />

        <TextArea
          minLength={7}
          maxLength={432}
          type="text"
          description="Veuillez ne pas envoyer de numéro de carte bancaire et, d'autres données sensibles."
          isRequired
          onChange={setFormTextArea}
          value={formTextarea}
          necessityIndicator="icon"
          label="Message"
          name="message"
          autoCapitalize="on"
          errorMessage={({ validationDetails }) =>
            validationDetails.valueMissing ? "Veuillez indiquer un message de 7 à 432 signes." : ""
          }
        />

        <Checkbox name="Données personnelles. obligatoire" isRequired>
          J&apos;accepte que mes données personnelles soient conservées par Tramway. Elles ne seront pas cédées ou
          revendues à des tiers.
        </Checkbox>

        <ButtonGroup align="end">
          <Button isPending={isLoading} type="submit" variant="primary" isDisabled={disabled}>
            {!isLoading ? "Envoyer" : "En cours..."}
          </Button>
          <Button type="reset" variant="secondary" style="fill">
            Effacer
          </Button>
        </ButtonGroup>
      </Form>
    </Flex>
  );
};

ContactUs.propTypes = {
  sessionPseudo: PropTypes.string,
  sessionEmail: PropTypes.string,
};

export default ContactUs;
