import useSWR from "swr";
import { fetchUsername } from "./fetchUsername";

export const useUsername = (userId) => {
  const { data, error, isLoading } = useSWR(userId ? ["fetch_username", userId] : null, () => fetchUsername(userId));

  return {
    username: data,
    error,
    isLoading,
  };
};
