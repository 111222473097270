import { useState, useCallback } from "react";
import { SCALE, COLORTHEMES } from "../constants/theme";

export const useUserAccessibility = () => {
  const [colorTheme, setColorTheme] = useState();
  const [scale, setScale] = useState(SCALE.large);
  let [open, setOpen] = useState(false);
  let [openDyslexic, setOpenDyslexic] = useState(false);

  const toggleScale = useCallback(() => {
    setScale((prevScale) => (prevScale === SCALE.medium ? SCALE.large : SCALE.medium));
  }, []);

  const toggleTheme = useCallback(() => {
    setColorTheme((prevTheme) => (prevTheme === COLORTHEMES.light ? COLORTHEMES.dark : COLORTHEMES.light));
  }, []);

  const toggleMenu = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const toggleTypo = useCallback(() => {
    setOpenDyslexic((prev) => !prev);
  }, []);

  return {
    colorTheme,
    scale,
    toggleScale,
    toggleTheme,
    toggleMenu,
    open,
    setOpen,
    openDyslexic,
    setOpenDyslexic,
    toggleTypo,
  };
};
