import { Text, Flex, Heading, Button } from "@adobe/react-spectrum";
import Heart from "@spectrum-icons/workflow/Heart";
import { PUBLIC_ROUTES } from "../../../constants/publicroutes";
import { useNavigate } from "react-router-dom";
import { memo } from "react";

// Style extrait pour éviter de répéter les styles inline
const headingStyle = {
  textDecoration: "underline",
  textDecorationStyle: "wavy",
  fontSize: "2rem",
};

const ThirdCTA = memo(function ThirdCTA() {
  const navigate = useNavigate();

  return (
    <Flex direction="column" gap="size-100" alignItems="start" justifyContent="center">
      <Heading level={2} UNSAFE_style={headingStyle}>
        Alternative Attitude
      </Heading>
      <Text>
        Que vous soyez maître composteur, randonneur, artiste ou yogi, ou que vous ayez choisi de vivre en harmonie sur
        une montagne sacrée, Tramway vous accueille dans un espace où les esprits libres et les cœurs connectés se
        rencontrent. Ici, l’amour se tisse à travers la créativité et la connexion à la nature, offrant une vie en phase
        avec les trésors du monde sauvage. Pour les âmes en quête d’une relation authentique, loin du conventionnel,
        l’aventure prend la forme de méditations partagées en marchant, de bivouacs sous les étoiles, de vibes sincères,
        de yoga en plein air et d’un engagement éco-responsable, le tout porté par une quête de sens et de connexion
        profonde. Trouve ton Coeur d'artichaut.
      </Text>
      <Button
        UNSAFE_style={{
          transform: "rotate(-2deg)",
          borderRadius: "20% 25% 20% 24%",
        }}
        alignSelf="end"
        variant="accent"
        style="fill"
        marginY="size-250"
        onPress={() => navigate(PUBLIC_ROUTES.creerCompte)}
      >
        <Heart />
        <Text>La vie a deux sur Tramway</Text>
      </Button>
      <Text alignSelf="end">Tramway ne vendra pas votre adresse.</Text>
    </Flex>
  );
});

export default ThirdCTA;
