import PropTypes from "prop-types";
import { BOTTOM_NAV } from "../constants/theme";

import { Flex, Text, View } from "@adobe/react-spectrum";

import Heart from "@spectrum-icons/workflow/Heart";
import Mailbox from "@spectrum-icons/workflow/Mailbox";
import Circle from "@spectrum-icons/workflow/Circle";
import Home from "@spectrum-icons/workflow/Home";
import User from "@spectrum-icons/workflow/User";

export default function GetFooterIcon(iconName, selected) {
  switch (iconName) {
    case BOTTOM_NAV.accueil:
      return (
        <>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            width="size-600"
            height="size-500"
            gap="size-10"
          >
            <View width="100%" marginBottom="size-100">
              <Home size={selected ? "M" : "S"} />
            </View>

            <View margin="0" padding="0">
              <Text UNSAFE_className="footer_icon">{iconName}</Text>
            </View>
          </Flex>
        </>
      );
    case BOTTOM_NAV.cercles:
      return (
        <>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            width="size-500"
            height="size-500"
            gap="size-10"
          >
            <View width="100%" marginBottom="size-100" padding="0">
              <Circle size={selected ? "M" : "S"} color={selected && "positive"} />
            </View>

            <View margin="0" padding="0">
              <Text UNSAFE_className="footer_icon">{iconName}</Text>
            </View>
          </Flex>
        </>
      );
    case BOTTOM_NAV.flash:
      return (
        <>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            width="size-500"
            height="size-500"
            gap="size-10"
          >
            <View width="100%" marginBottom="size-100">
              <Heart size={"M"} color={selected && "negative"} />
            </View>
            <View margin="0" padding="0">
              <Text UNSAFE_className="footer_icon">{iconName}</Text>
            </View>
          </Flex>
        </>
      );
    case BOTTOM_NAV.message:
      return (
        <>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            width="size-500"
            height="size-500"
            gap="size-10"
          >
            <View width="100%" marginBottom="size-100">
              <Mailbox size={selected ? "M" : "S"} color={selected && "informative"} />
            </View>

            <View margin="0" padding="0">
              <Text UNSAFE_className="footer_icon">{iconName}</Text>
            </View>
          </Flex>
        </>
      );
    case BOTTOM_NAV.profile:
      return (
        <>
          <Flex direction="column" justifyContent="center" alignItems="center" width="size-500" height="size-500">
            <View width="100%" marginBottom="size-100">
              <User size={selected ? "M" : "S"} />
            </View>

            <View>
              <Text UNSAFE_className="footer_icon">{iconName}</Text>
            </View>
          </Flex>
        </>
      );
    default:
      return "";
  }
}

GetFooterIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
};
