import { useContext } from "react";
import { Flex, Header, View } from "@adobe/react-spectrum";
import TramwayAppIcon from "./TramwayIcon";
import NavItems from "./NavItems";
import Notifications from "./Notifications";
import LoginBtn from "./LoginBtn";
import UserSession from "../contexts/UserSession";

const useSessionRole = () => {
  const { sessionRole } = useContext(UserSession);
  return sessionRole;
};

const Nav = () => {
  const sessionRole = useSessionRole();

  return (
    <Header height="size-700" top="static-size-0" zIndex="5" position="sticky">
      <View backgroundColor="gray-50" height="size-700">
        <Flex justifyContent="space-between" marginX="size-100" alignItems="center">
          <Flex gap="size-200" alignItems="center" justifyContent="left" height="size-700">
            <NavItems />
            <TramwayAppIcon />
          </Flex>
          {sessionRole !== "authenticated" ? <LoginBtn /> : <Notifications />}
        </Flex>
      </View>
    </Header>
  );
};

export default Nav;
