import { Well, Slider, Text, Flex, Avatar } from "@adobe/react-spectrum";
import useReviews from "../../../hooks/useReviews";

function SocialProof() {
  const { averageRate, error } = useReviews();
  const reviews = [1, 2, 3];

  const generateStars = (value) => {
    const starsCount = Math.round(value);
    return Array.from({ length: starsCount }, (_, index) => (
      <span key={index} title="emoji : étoile">
        ⭐
      </span>
    ));
  };

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" width="100%" wrap>
        <Well marginY="size-250" width={{ M: "45%", L: "45%" }}>
          <Flex direction="column" gap="size-250">
            {/* Avatar + Rating Stars */}
            <Flex width="100%" direction="row" justifyContent="space-between" alignItems="center">
              {/* Avatars */}
              <Flex direction="row" alignItems="center" maxWidth="65%">
                {reviews.map((review) => (
                  <Avatar
                    key={review}
                    size="avatar-size-700"
                    src={`   https://api.dicebear.com/9.x/pixel-art/svg?seed=${review}&mouth=happy01,happy03,happy04,happy05,happy06,happy07,happy08,happy09,happy10,happy11,happy12,happy13`}
                    alt="Les utilisateurs Tramway vous donnent leurs avis"
                  />
                ))}
              </Flex>

              <Text>{generateStars(averageRate)}</Text>
            </Flex>

            {/* Slider */}
            <Slider
              trackGradient={["white", "DarkGoldenRod"]}
              width="100%"
              minValue={1}
              step={0.1}
              maxValue={5}
              label="Vos évaluations"
              value={error ? 4.5 : (averageRate ?? 4.5)}
              getValueLabel={(x) => `${x} / 5`}
            />
          </Flex>
        </Well>

        <Flex width={{ M: "45%", L: "45%" }} justifyContent="center" alignItems="center">
          <Text>
            S’échapper en pleine nature avec sa Moitié. Tous les Célibataires : Une magnifique opportunité de trouver
            l’Amour. Handi & PMR friendly. Trouve ton Coeur d'Artichaut...
            <span title="emoji :main en forme de coeur">🫶</span>
          </Text>
        </Flex>
      </Flex>
    </>
  );
}

export default SocialProof;
