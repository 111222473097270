import { BOTTOM_NAV } from "./theme";
import { PRIVATE_ROUTES } from "./privateroutes";

const BOTTOM_NAV_ITEMS = [
  {
    to: `/${PRIVATE_ROUTES.private}/${PRIVATE_ROUTES.accueil}`,
    label: BOTTOM_NAV.accueil,
  },
  {
    to: `/${PRIVATE_ROUTES.private}/${PRIVATE_ROUTES.cercles}`,
    label: BOTTOM_NAV.cercles,
  },
  {
    to: `/${PRIVATE_ROUTES.private}/${PRIVATE_ROUTES.flash}`,
    label: BOTTOM_NAV.flash,
  },
  {
    to: `/${PRIVATE_ROUTES.private}/${PRIVATE_ROUTES.messages}`,
    label: BOTTOM_NAV.message,
  },
];

export { BOTTOM_NAV_ITEMS };
