import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";

export const useUserSession = () => {
  const [sessionData, setSessionData] = useState({
    session: null,
    sessionEmail: "",
    sessionTerms: null,
    sessionIsActive: false,
    sessionRole: "",
    sessionEvent: "",
    sessionLastSignIn: "",
    sessionConfirmed: "",
    sessionExpires: null,
    currentLevelMFA: null,
    nextLevelMFA: null,
  });

  const updateSessionIsActive = async () => {
    if (!sessionData.session) return;

    try {
      const { data, error } = await supabase
        .from("users_status")
        .select("is_active")
        .eq("user_id", sessionData.session.user?.id)
        .single();

      if (error) {
        console.error("Error fetching user status:", error);
        return;
      }

      setSessionData((prevData) => ({
        ...prevData,
        sessionIsActive: data?.is_active || false,
      }));
    } catch (error) {
      console.error("Error updating session isActive:", error);
    }
  };

  const updateTerms = async () => {
    if (!sessionData.session) return;

    try {
      const { data, error } = await supabase
        .from("terms")
        .select("accepted_at")
        .eq("user_id", sessionData.session.user?.id)
        .single();

      if (error) {
        console.error("Error fetching terms:", error);
        return;
      }

      setSessionData((prevData) => ({
        ...prevData,
        sessionTerms: data?.accepted_at || null,
      }));
    } catch (error) {
      console.error("Error updating session terms:", error);
    }
  };

  useEffect(() => {
    const fetchSessionData = async () => {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();

      if (error) {
        console.error("Error fetching session:", error);
        return;
      }

      if (session) {
        setSessionData((prevData) => ({
          ...prevData,
          session,
          sessionRole: session?.user?.role || "",
          sessionEmail: session?.user?.email || "",
          sessionLastSignIn: session?.user?.last_sign_in_at || "",
          sessionConfirmed: session?.user?.confirmed_at || "",
          sessionExpires: session?.expires_in || null,
        }));

        try {
          const [termsResponse, statusResponse] = await Promise.all([
            supabase.from("terms").select("accepted_at").eq("user_id", session.user.id).single(),
            supabase.from("users_status").select("is_active").eq("user_id", session.user.id).single(),
          ]);

          console.log(statusResponse);
          const termsAcceptedAt = termsResponse.data?.accepted_at || null;
          const isActive = statusResponse.data?.is_active || false;

          setSessionData((prevData) => ({
            ...prevData,
            sessionTerms: termsAcceptedAt,
            sessionIsActive: isActive,
          }));
        } catch (error) {
          console.error("Error fetching terms or user status:", error);
        }
      }
    };

    const handleAuthStateChange = (_event, session) => {
      setSessionData((prevData) => ({
        ...prevData,
        session,
        sessionRole: session?.user?.role || "",
        sessionEmail: session?.user?.email || "",
        sessionLastSignIn: session?.user?.last_sign_in_at || "",
        sessionConfirmed: session?.user?.confirmed_at || "",
        sessionExpires: session?.expires_in || null,
        sessionEvent: _event,
      }));
    };

    const fetchMFAData = async () => {
      const {
        data: { currentLevel, nextLevel },
        error,
      } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();

      if (error) {
        console.error("Error fetching MFA data:", error);
        return;
      }

      setSessionData((prevData) => ({
        ...prevData,
        currentLevelMFA: currentLevel,
        nextLevelMFA: nextLevel,
      }));
    };

    // Fetch initial session data
    fetchSessionData();

    // Subscribe to auth state changes
    const { data: authListener } = supabase.auth.onAuthStateChange(handleAuthStateChange);

    // Fetch MFA data
    fetchMFAData();

    // Cleanup subscription when component unmounts
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return {
    ...sessionData,
    updateSessionIsActive,
    updateTerms,
    setNextLevelMFA: (nextLevel) => setSessionData((prevData) => ({ ...prevData, nextLevelMFA: nextLevel })),
    setCurrentLevelMFA: (currentLevel) =>
      setSessionData((prevData) => ({ ...prevData, currentLevelMFA: currentLevel })),
  };
};
