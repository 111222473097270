import PropTypes from "prop-types";
import { SessionProvider } from "./SessionProvider.jsx";
import { AccessibilityProvider } from "./AccessibilityProvider.jsx";
function GlobalProvider({ children }) {
  return (
    <SessionProvider>
      <AccessibilityProvider>{children}</AccessibilityProvider>
    </SessionProvider>
  );
}

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalProvider;
