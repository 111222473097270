import { useState, memo } from "react";
import { CGV } from "../constants/CGV";
import { Item, ListBox, Text, View, Section } from "@adobe/react-spectrum";
import ChevronRight from "@spectrum-icons/workflow/ChevronRight";

const Cgv = memo(() => {
  const [word, setWord] = useState(new Set());

  return (
    <>
      <View key={word}>
        <ListBox
          selectionMode="multiple"
          aria-label="Les CGV point par point."
          items={CGV}
          onSelectionChange={(selected) => setWord(selected)}
        >
          {(item) => (
            <Section key={item?.id} items={item.children} title={item.title}>
              {(item) => (
                <Item textValue={item.title}>
                  <ChevronRight />
                  <Text>{item?.title}</Text>
                  <Text slot="description">{item?.text}</Text>
                </Item>
              )}
            </Section>
          )}
        </ListBox>
      </View>
    </>
  );
});

Cgv.displayName = "Cgv";
export default Cgv;
