import PropTypes from "prop-types";
import UserAccessibility from "../contexts/UserAccessibility";
import { useUserAccessibility } from "../hooks/useUserAccessibility";
import { defaultTheme, Provider } from "@adobe/react-spectrum";

import { I18nProvider } from "react-aria";
export const AccessibilityProvider = ({ children }) => {
  const accessibility = useUserAccessibility();

  return (
    <UserAccessibility.Provider value={accessibility}>
      <Provider theme={defaultTheme} colorScheme={accessibility.colorTheme} scale={accessibility.scale}>
        <I18nProvider locale="fr-FR">{children}</I18nProvider>
      </Provider>
    </UserAccessibility.Provider>
  );
};

AccessibilityProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AccessibilityProvider;
